$(window).on( "categoryPage.rendered resize scroll", function(event) {
	var isMobileVersion = $('.size_mobile');
	var isTabletVersion = $('.size_tablet.portrait');
	var prodCont = $('.featured-products');
	if( (isMobileVersion.length || isTabletVersion.length) && !prodCont.length){
		$('[data-fabi="category-info"] .block-category-info').prependTo(".category-list");
	}
	if(!(isMobileVersion.length || isTabletVersion.length) && $('#products-container .block-category-info').length) {
		$('#products-container .block-category-info').prependTo('[data-fabi="category-info"]');
	}
});

$(document).ready(function(){
	if($('.size_desktop').length){
		$('.video-desktop').each(function(){
			var player = new Vimeo.Player(this);
			player.setLoop(true);
			player.setVolume(0);
			player.play();
		});
	}	else {
		$('.video-mobile').each(function(){
			var player = new Vimeo.Player(this);
			player.setLoop(true);
			player.setVolume(0);
			player.play();
		});
	}
});

function widthProductImageCMS () {
	if($('.size_desktop').length && $('.componentProduct').length){
		var HtmlW = $('html').width();
		$('.componentProduct').css('width', HtmlW+'px');
		$('.componentProduct').css('margin-left', '0px');
		var OffsetLeft = $('.componentProduct').offset().left;
		var Padding = parseInt($("#site_main").css("padding-left"));
		$('.componentProduct').css('margin-left', '-'+(OffsetLeft+Padding)+'px');
	}
}

$(document).ready(function(){
	widthProductImageCMS();
});
$(window).resize(function(){
	widthProductImageCMS();
});
/*
var doit;
window.onresize = function(){
  clearTimeout(doit);
  doit = setTimeout(widthProductImageCMS, 500);
}; */

/* ADD CUSTOM FISCAL CODE ON P_IVA ONLY IN ITALY */
// on checkout page
$(document).ready(function() {
	if($('#login-area').length) {
		var GuestCheckInvoice = $('#guestcheckbox_request_invoce');
		var GuestCountry = $('#guestshipping_address_country_id');
		var GuestFakeFiscalCode = $('#guestshipping_address_p_iva_outside').detach();
		var GuestFiscalCode = $('#guestshipping_address_fiscal_code').detach();
		function GuestCheckFC () {
			if (GuestCountry.val() == 106) { // if is italy selected
				if(GuestCheckInvoice.is(':checked')){ // if is checked request invoice
					$('.guest_fiscal_code_field').append(GuestFiscalCode);
					GuestFakeFiscalCode.detach();
					$('#guestshipping_address_p_iva_outside-error').detach();
				} else {
					$('.guest_fiscal_code_field_fake').append(GuestFakeFiscalCode);
					GuestFakeFiscalCode.show();
					GuestFiscalCode.detach();
				}
			} else {
				$('.guest_fiscal_code_field').append(GuestFiscalCode);
				GuestFakeFiscalCode.detach();
				$('#guestshipping_address_p_iva_outside-error').detach();
			}
		}
		GuestCheckFC();
		GuestCountry.change(function(){
			GuestCheckFC();
		});
		GuestCheckInvoice.change(function(){
			GuestCheckFC();
		});


		var UserCheckInvoice = $('#registercheckbox_request_invoce');
		var UserCountry = $('#registershipping_address_country_id');
		var UserFakeFiscalCode = $('#registershipping_address_p_iva_outside').detach();
		var UserFiscalCode = $('#registershipping_address_fiscal_code').detach();
		function UserCheckFC () {
			if (UserCountry.val() == 106) { // if is italy selected
				if(UserCheckInvoice.is(':checked')){ // if is checked request invoice
					$('.register_fiscal_code_field').append(UserFiscalCode);
					UserFakeFiscalCode.detach();
					$('#registershipping_address_p_iva_outside-error').detach();
				} else {
					$('.register_fiscal_code_field_fake').append(UserFakeFiscalCode);
					UserFakeFiscalCode.show();
					UserFiscalCode.detach();
				}
			} else {
				$('.register_fiscal_code_field').append(UserFiscalCode);
				UserFakeFiscalCode.detach();
				$('#registershipping_address_p_iva_outside-error').detach();
			}
		}
		UserCheckFC();
		UserCountry.change(function(){
			UserCheckFC();
		});
		UserCheckInvoice.change(function(){
			UserCheckFC();
		});
	}
});

// user insert address, after registration page
$(document).ready(function(){
	if($('#addressForm').length){

		var UserCheckInvoice = $('#user_request_invoice');
		var UserCountry = $('#country_id');
		var UserFakeFiscalCode = $('#fiscal_code_fake').detach();
		var UserFiscalCode = $('#fiscal_code_real').detach();
		function UserCheckFC () {
			if (UserCountry.val() == 106) { // if is italy selected
				if(UserCheckInvoice.is(':checked')){ // if is checked request invoice
					$('.register_fiscal_code_field').append(UserFiscalCode);
					UserFakeFiscalCode.detach();
				} else {
					$('.register_fiscal_code_field_fake').append(UserFakeFiscalCode);
					UserFakeFiscalCode.show();
					UserFiscalCode.detach();
				}
			} else {
				$('.register_fiscal_code_field').append(UserFiscalCode);
				UserFakeFiscalCode.detach();
			}
		}
		UserCheckFC();
		UserCountry.change(function(){
			UserCheckFC();
		});
		UserCheckInvoice.change(function(){
			UserCheckFC();
		});

	}
});

// user insert address on MODAL
$(document).ready(function(){
	if($('#address_form_modal').length){
		//add observer
		var $div = $("#address_form_modal");
		var observer = new MutationObserver(function(mutations) {
			mutations.forEach(function(mutation) {
				if (mutation.attributeName === "class") {
					var attributeValue = $(mutation.target).prop(mutation.attributeName);
					if($div.hasClass('in')){
						//controllFakeFC();

						var UserCheckInvoice = $('#user_request_invoice');
						var UserCountry = $('#country_id');

								var UserFakeFiscalCode = $('#p_iva_outside').detach();
								var UserFiscalCode = $('#fiscal_code').detach();
								function  UserCheckFC  () {
									if (UserCountry.val() == 106) { // if is italy selected
										if(UserCheckInvoice.is(':checked')){ // if is checked request invoice
											$('.register_fiscal_code_field').append(UserFiscalCode);
											UserFakeFiscalCode.detach();
										} else {
											$('.register_fiscal_code_field_fake').append(UserFakeFiscalCode);
											UserFakeFiscalCode.show();
											UserFiscalCode.detach();
										}
									} else {
										$('.register_fiscal_code_field').append(UserFiscalCode);
										UserFakeFiscalCode.detach();
									}
								};

						UserCheckFC();

						UserCountry.change(function(){
							UserCheckFC();
						});
						UserCheckInvoice.change(function(){
							UserCheckFC();
						});
					}
				}
			});
		});
		observer.observe($div[0], {
			attributes: true
		});
	}
});
/* END ADD CUSTOM FISCAL CODE ON P_IVA ONLY IN ITALY */
/* facebook event */
$( document ).on( 'zg.product.addedToCart', function () {
	var temp_id = $('.product-details[data-zg-role="product"]').attr("data-id");
	if(typeof fbq != 'undefined'){
		//fbq('track', 'AddToCart');
		fbq('track', 'AddToCart', {
        content_ids: [temp_id],
        content_type: 'product_group'
      });     
	}
	if(typeof gtag != 'undefined'){
		gtag("event", "add_to_cart", {
			items: [
				{
					item_id: '12345'
				}
			]
		});    
	}
});


// product page set cookie id product
/*
if( $('body').attr('data-template') == "productMgr.html" ){ 
	zgEraseCookie('LastProdId');
	zgEraseCookie('LastProdIdComeFrom');
	zgCreateCookie('LastProdId', ZG_CONFIG.initialUriComponents.pid, 1);
	zgCreateCookie('LastProdIdComeFrom', ZG_CONFIG.initialUriComponents.frmCatID, 1);
} else if (($('body').attr('data-template') == "categoryMgr.html") && zgReadCookie('LastProdId') && (performance.navigation.type == 2) && (zgReadCookie('LastProdIdComeFrom') == ZG_CONFIG.initialUriComponents.frmCatID)) {
	var JustSrolled = false;
	var ProdIdToView = 'cat_prod_'+zgReadCookie('LastProdId');
	zgEraseCookie('LastProdId');
	zgEraseCookie('LastProdIdComeFrom');
	$(window).on("categoryPage.rendered resize scroll", function (event) {	
		if( jQuery('#'+ProdIdToView).length > 0 ) {
			if (!JustSrolled) {
				document.getElementById(ProdIdToView).scrollIntoView(false);
				JustSrolled = true;
			}
		} else {
			jQuery('#scroller-loader button').click();
		}
	});	
} else {
	zgEraseCookie('LastProdId');
	zgEraseCookie('LastProdIdComeFrom');
}*/

jQuery('document').ready(function() {
		if(window.innerWidth < 1024 && jQuery('.black-promo-banner').length > 0) {
			document.getElementsByClassName("landing_links")[0].style.marginTop = (document.getElementsByClassName('content-promtion-block')[0].clientHeight - 19)+'px';
		}
});
