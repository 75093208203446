function ScrollMenu() { /* STICKY FIXED MENU DESKTOP */
	if ( $(window).width() > 1023 ) {

        var $sticky = $('#checkout-info'); //Object to manage
        if ( $sticky.length ) { // make sure ".sticky" element exists we are in checkout pages
            var $stickyrStopper = $('#site_footer'); //Object observed to make decisions
            var bottomLimiterMenu = $('#checkout-info .end_desk_menu'); //Object to the bottom limit of the menu
            var blockHeight = $sticky.innerHeight();
            $sticky.attr('data-initialHeight', blockHeight);
            if( $(window).scrollTop() != 0 ) { // always but not in just loaded
                var bottomLimiterMenu_position = bottomLimiterMenu.offset().top; //Bottom menu position
                var stickyStopperPosition = $stickyrStopper.offset().top; //Up footer position
                var diff = (bottomLimiterMenu_position - stickyStopperPosition); // if greater than 0 footer si up the menu!!!

                $sticky.css({ height: $sticky.attr('data-initialHeight') - diff });
            }

            $(window).scroll(function(){ // scroll event
                var $stickyrStopper = $('#site_footer'); //Object observed to make decisions
                var bottomLimiterMenu = $('#checkout-info .end_desk_menu'); //Object to the bottom limit of the menu
                if( $(window).scrollTop() != 0 ) { // always but not in just loaded
                    var bottomLimiterMenu_position = bottomLimiterMenu.offset().top; //Bottom menu position
                    var stickyStopperPosition = $stickyrStopper.offset().top; //Up footer position
                    var diff = (bottomLimiterMenu_position - stickyStopperPosition); // if greater than 0 footer si up the menu!!!

                    $sticky.css({ height: $sticky.attr('data-initialHeight') - diff });
                }
            });

        }
        else{

            $sticky = $('.mainNavigation'); //Object to manage
            if ( $sticky.length ) { // make sure ".sticky" element exists we are in normal pages!
                var $stickyrStopper = $('#site_footer'); //Object observed to make decisions
                var bottomLimiterMenu = $('.mainNavigation .end_desk_menu'); //Object to the bottom limit of the menu
                if( $(window).scrollTop() != 0 ) { // always but not in just loaded
                    var bottomLimiterMenu_position = bottomLimiterMenu.offset().top; //Bottom menu position
                    var stickyStopperPosition = $stickyrStopper.offset().top; //Up footer position
                    var diff = (bottomLimiterMenu_position - stickyStopperPosition); // if greater than 0 footer si up the menu!!!

                    $('.nav-stacked').css({ height: $('.nav-stacked').innerHeight() - diff });
                }

                $(window).scroll(function(){ // scroll event
                    var $stickyrStopper = $('#site_footer'); //Object observed to make decisions
                    var bottomLimiterMenu = $('.mainNavigation .end_desk_menu'); //Object to the bottom limit of the menu
                    if( $(window).scrollTop() != 0 ) { // always but not in just loaded
                        var bottomLimiterMenu_position = bottomLimiterMenu.offset().top; //Bottom menu position
                        var stickyStopperPosition = $stickyrStopper.offset().top; //Up footer position
                        var diff = (bottomLimiterMenu_position - stickyStopperPosition); // if greater than 0 footer si up the menu!!!

                        $('.nav-stacked').css({ height: $('.nav-stacked').innerHeight() - diff });
                    }
                });

            }
        }

	}
}

//
/* DESKTOP */
//

$(document).ready(function() { ScrollMenu(); });
$(window).resize(function()  { ScrollMenu(); });

$(document).ready(function() {
	if( $("body").attr("data-contentid") == "596752"){ //se siamo in pagina fabi
		$("a:contains('fabi')").parent().find(".son").first().toggleClass("opened"); //Apro menu desktop di fabi
		$(".landing_links").find(".toFabi a").css( "color", "#010101" ); //coloro di nero il menu mobile in area fabi
		$("#navbar .firstLevel .areaFABI").click(); //clicco il menu mobile area fabi
	}
	else if( $("body").attr("data-contentid") == "596788"){ //se invece siamo in pagina barracuda
		$("a:contains('barracuda')").parent().find(".son").first().toggleClass("opened"); //apro il menu desktop di barracuda
		$(".landing_links").find(".toBarracuda a").css( "color", "#010101" ); //coloro di nero il menu mobile in area barracuda
		$("#navbar .firstLevel .areaBARRACUDA").click(); //clicco il menu mobile area barracuda
	}
	else{ //non siamo ne in barracuda //////ne in fabi
		$(".landing_links").find(".toFabi a").css( "color", "#010101" ); //coloro di nero il menu mobile in area fabi
		$(".landing_links").find(".toBarracuda a").css( "color", "#010101" ); //coloro di nero il menu mobile in area barracuda
		$("#navbar .firstLevel .areaFABI").click(); //clicco il menu mobile area fabi
	}
});
/*LANDING B2B HIDE*/ 
if($('.contentType_Page_landing[data-contentid="684439"]').length ==0){
	// open default menu desktop
	var currentUrl = window.location.href.toString().split('?')[0]; //url senza indirizzo di base
	var currentUrl = SGL_JS_CURRURL;
	var menuItem = $('.mainNavigation [href="' + currentUrl + '"]');
	if( menuItem.length ){
		if(menuItem.parent().hasClass('level-4')) {
			menuItem.addClass('current_child');
			menuItem.parent().addClass('current_parent');
		}
		menuItem.parents("ul.son").addClass("opened");
	} else {
		var ToOpen = LastLevelToOpen.toString().split(SGL_JS_WEBROOT)[1];
		menuItem = $('.mainNavigation [href="' + ToOpen + '"]');
		menuItem.addClass('current_child');
		menuItem.parent().addClass('current_parent');
		menuItem.parents("ul.son").addClass("opened");
	}
}

//open level-1
 $('.level-1.dropdown > a span').click(function() { // only level-1 with dropdown links
	if($(this).hasClass('link-label')){
	 	event.preventDefault();
		if(!$(this).parent().parent().find(".son").first().hasClass('opened')){
			$('.mainNavigation .opened').removeClass('opened');
			$('.mainNavigation .active').removeClass('active');
			$(this).parent().parent().find(".son").first().addClass('opened');
		} else {
			$(this).parent().parent().find(".son").first().removeClass('opened');
		}
	}
});

// Functions
$(".father").click(function(event){ //for level-2 and level-3
	if( $(this).hasClass("preventDefault") ){
		event.preventDefault();
	}
	if( !$(this).hasClass('active') ){
		$(this).parent().parent().find(".active").removeClass("active");
		$(this).parent().parent().find(".opened").removeClass("opened");
		$(this).addClass("active");
		$(this).parent().find(".son").first().addClass("opened");
	} else {
		//$(this).removeClass('active');
		$(this).parent().find(".opened").removeClass("opened");
		$(this).parent().find(".active").removeClass("active");
	}

	ScrollMenu();
});


//
/* MOBILE */
//

// Script per sistemare l'apertura del menu mobile, blocca l'html al click e lo sblocca alla chiusura
$("button.navbar-toggle").click(function(){
	if( $(this).hasClass("collapsed") ){ $("html").addClass("blocked"); }
	else{ $("html").removeClass("blocked"); }
});

// Script per il menu mobile
function chiudiTutto(){
	$(".child-FABI, .child-BARRACUDA").removeClass("active").find("li").removeClass("opened");;
	$(".areaFABI, .areaBARRACUDA").removeClass("active");
}

/*
$(".firstLevel .areaFABI").click(function(){
	chiudiTutto();
	$(".child-FABI.secondLevel").addClass("active");
	$(".areaFABI").addClass("active");
});

$(".firstLevel .areaBARRACUDA").click(function(){
	chiudiTutto();
	$(".child-BARRACUDA.secondLevel").addClass("active");
	$(".areaBARRACUDA").addClass("active");
});
*/

$("#navbar .submenu").click(function(event){
    event.stopPropagation();
	console.log('click');
    if( $(this).hasClass("opened") ){
        $(this).parent().find("li").removeClass("opened");
        //$(this).removeClass("opened");//.find("li").removeClass("opened");
    }
    else{
        $(this).parent().find("li").removeClass("opened");
        $(this).addClass("opened");
    }
});
