/**
 * EDIT by productInfo
 * Plugin for manage product with GRID for specific Cluster
 * - display product with correct configuration
 * - check quantity
 * - add to cart
 * 
 * 
 *
 * @author: Basile Andrea <abasile[at]zerogrey[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg.product.addedToCart Product added to cart all skus selected
 * @type {object}
 */

/**
 * @event document#zg.product.ready Product is ready
 * @type {object} data of the product
 */

/**
 * @event document#zg.product.optionUpdated Options are ready
 * @type {object} data of the options
 */

/**
 * @event document#zg.product.addToCart. Click to add to cart button
 * @type {null}
 */

/**
 * @event document#zg.getProductInfo.productCreated Product rendered by handlebars (like in
 *     category page)
 * @type {object} See 2101-zg-getProductInfo for more info
 */


/**
 * @event document#zg.getProductListInfo.success  When the list of product in cart are loaded check
 *     update the quantity availability checking how many product the user are in cart
 * @type {string} type - Type of list (ex "cart")
 */

/**
 * @event document#zg.urimgr.updatedUri.  The url has been update
 * @type {object}
 * @property {string} base - Url without get parameters
 * @property {object} components - Object with all components of the url
 * @property {string} hash - Hash value
 * @property {int} index - TO CHECK
 * @property {object} status - Object with all data (clearUrl,options,pid, ecc ecc)
 */


/* global _, DEBUG, handlebarsTemplates, JS_TRANSLATIONS, zgGalleries, zgGet, zgPost, zgProcessProductImages */

(function ( $, _ ) {
	'use strict';

	/**
	 * @selector data-zg-role="product" The plugin start if there is the selector in the dom when
	 *     the page load
	 */
	var selector = '[data-zg-role="product-grid"]';


	// PRODUCT CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var Product = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( Product.DEFAULTS );
		this.setOptions( options );

		this.product = null;

		// basic script configuration
		this.options.namespace = this.options.namespace || _.uniqueId();

		this.availability        = null;
		this.isAvailable         = false;
		this.availabilityChecked = false;

		// initialize object
		this.setEventHandlers();
	};


	/**
	 * @param {string} [elementAddToCart] Add to cart button
	 *     you can put your email for inform you when return in stock)
	 * @param {int}    [disabledSkus] If the current SKU has been 'disabled' we don't allow to
	 *     continue. This is useful for the 'product exchange' (don't allow to exchange a product
	 *     with itself)
	 * @param {array} [disableOptions] Disable all options values not assigned to any SKU. If true
	 *     it will be applied to all product options. You can also use an array of product options
	 *     ids.
	 * @param {boolean} [enableAddToCart] If false disable add to cart

	 * @param {boolean} [namespace] Used in return table only for specific unique name
	 * @param {string} [templatePrice] Handlebars template for product price
	 * @param {boolean} [updateUri] If true update current page url and/or quickbuy/product page
	 *     opening options
	 * @param {boolean} [productReloadPageOnAdd] If true when the user add to cart the product,
	 *     reload the page
	 */
	Product.DEFAULTS = {
		elementAddToCart:      	'[data-zg-role="add-to-cart"]',
		gridOptions:           	'[data-zg-role="render-product-grid"]',
		singleOption:           '[data-zg-role="single-option"]',
		btnPlus:           		'[data-action="plus"]',
		btnMinus:           	'[data-action="minus"]',
		inputQuantity:           	'[data-zg-role="input-quantity"]',

		disabledSkus:    null,

		disableOptions: true,

		enableAddToCart:     true,

		checkAvailabilityOnInit: true,

		namespace: null,

		templatePrice: 'product-price',

		updateUri: false,

		productReloadPageOnAdd: false
	};


	/**
	 * If the product is available it triggers the request to add to the cart.
	 * Otherwise it requests the availability (if unknown) or displays an error msg.
	 *
	 * @method addToCart
	 * @fires document#zg-error the product is not available.
	 */
	Product.prototype.addToCart = function () {
		if ( this.product ) {
			this.addToCartRequest();
		}
	};


	/**
	 * @method addToCartRequest
	 * @fires document#zg-error Custom product make an error
	 */

	/**
	 * @method addToCartRequest
	 * @fires document#zg.product.addedToCart Product added to the cart
	 */

	/**
	 * @method addToCartRequest
	 * @fires document#zg.product.selectedConfiguration. Selected combination trigger. Used for the
	 *     exchange product process
	 */

	/**
	 * Adds the product to the cart.
	 *
	 */
	Product.prototype.addToCartRequest = function () {
		var addToCart;
		var callback;
		var customValues;
		var data;
		var i;
		var value;
		var that= this;
		data = {items:[]};
		that.$listOptions.each(function(){
			data.items.push({
				product_id: that.product.id,
				sku:        $(this).data('sku'),
				quantity:   $(this).find('[data-quantity]').val()
			});
		});



		if ( this.product && this.options.enableAddToCart ) {
			addToCart = this.options.enableAddToCart;

			// For custom products:
			if ( this.product.custom_values ) {
				customValues = [];
				value        = null;

				for ( i = 0; i < this.product.custom_values.length && addToCart; i++ ) {
					value = this.$element.find( '[name="acustom_' + this.product.custom_values[i].id + '"]' ).val() || null;

					if ( value ) {

						customValues.push( {
							customization_id:    this.product.custom_values[i].id,
							customization_value: value
						} );

					} else if ( this.product.custom_values[i].is_mandatory ) {
						addToCart = false;

						$( document ).trigger( 'zg-error', [{
							eventType: 'custom-product',
							message:   JS_TRANSLATIONS.product_customizationError
						}] );
					}
				}

				data = $.extend( {}, data, { aCustom: customValues } );
			}

			// Add to the cart
			if ( addToCart ) {
				callback = {
					success: (function ( data, status ) {
						// product successfully added  \o/
						//this.cleanQuantity( 1 );

						$( document ).trigger( 'zg.product.addedToCart', [status, this.product, data] );

						if ( this.options.productReloadPageOnAdd ) {
							window.location.reload();
						}
					}).bind( this, data ),

					error: function () {
						// Something went wrong. Request the cart items again.
						$( document ).trigger( 'zg.getProductList.request-info', ['cart'] );
					}
				};

				// disable the addToCart element.
				$( this.options.elementAddToCart, this.$element ).prop( 'disabled', true ).addClass("adding");

				zgPost( 'addProductToCart', data, null, callback );
				$(this.options.inputQuantity).val(0);
			}
		}

		// selected combination trigger. Used for the exchange product process
		$( document ).trigger( 'zg.product.selectedConfiguration.' + this.options.namespace, [data] );
	};






	/**
	 * This will change the value of skus in with grid.
	 * 
	 * Returns list of skus
	 *
	 * @param 
	 *
	 * @returns { render }
	 */
	Product.prototype.createGrid = function ( ) {
		var skus=this.product.skus;
		var options=this.product.options;
		for(var sku in skus){
			for(var option_sku in skus[sku].options){
				var id =skus[sku].options[option_sku];
				var qty= 0;
				var opt= options[option_sku].values[id];
				if(typeof options[option_sku].values[id].images !== "undefined"){
					qty =  Object.keys(options[option_sku].values[id].images).length;
				}
				skus[sku].options[option_sku]={id:id, name:options[option_sku].values[id].name, has_image:qty};
			}
		}
		this.$grid.html( handlebarsTemplates.render( 'product-options-grid', skus ) );
	};

	/**
	 * @method setEventHandlers
	 * @listen document#zg.product.addToCart. When the user click on Add to Cart button call
	 *     addToCart function
	 */

	/**
	 * @method setEventHandlers
	 * @listen document#zg.getProductListInfo.success When the list of product in cart are loaded
	 *     check update the quantity availability checking how many product the user are in cart
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.urimgr.updatedUri.  When the url has been updated update the product info
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.product.optionSelected. COMMENTED update selected option interface
	 */
	Product.prototype.setEventHandlers = function () {
		// Add to cart (button)
		var that = this;
		this.$element.on( 'click.zg.product.addToCart', this.options.elementAddToCart, (function ( e ) {
			e.preventDefault();
			this.addToCart();
		}).bind( this ) );

		// Add to cart (event)
		$( document ).on( 'zg.product.addToCart.' + this.options.namespace, this.addToCart );

		this.$element.on( 'change.zg.sku.quantity', $(this.options.singleOption).find('input[data-quantity]'), (function ( e ) {
			this.updateButton();
		}).bind( this ) );
		// ---------------------------------------------------------------------
		this.$element.on( 'click.zg.quantity.plus', this.options.btnPlus, function ( e ) {
			var $prev =$(this).prev();
			var value =Number($prev.val())+1;
			var max= $prev.attr('max');
			if (value > max){
				value=max;
			}
			$prev.val(value);		
			that.updateButton();
			//that.updateAvailability();
		});

		this.$element.on( 'click.zg.quantity.minus', this.options.btnMinus, function ( e ) {
			var $next =$(this).next();
			var value =Number($next.val())-1;
			var min= $next.attr('min');
			if (value < min){
				value=min;
			}
			$next.val(value);	
			that.updateButton();
		});
		this.$element.on( 'change keyup paste', this.options.inputQuantity, function ( e ) {
			console.log('change');
			var value= parseInt($(this).val());
			if( !$.isNumeric( value ) ){
					$(this).val(0);
			}else{
				that.updateAvailability();
				if(value > $(this).attr('max')){
					$(this).val($(this).attr('max'));
				}else if(value < $(this).attr('min')){
					$(this).val($(this).attr('min'));
				}else{
					$(this).val(value);
				}
			}
			that.updateButton();
		});

		$( document ).on( 'zg.getProductListInfo.success', (function ( e, type ) {
			if ( type === 'cart' ) {
				this.updateAvailability();
			}
		}).bind( this ) );

		// ---------------------------------------------------------------------

		// the url has been updated
		$( document ).on( 'zg.urimgr.updatedUri.' + this.options.namespace, (function ( e, info ) {
			if (
				this.options.updateUri &&
				info &&
				info.status &&
				info.status.data &&
				info.status.data.trigger === 'Product' &&
				info.status.data.pid !== this.product.id
			) {
				this.$element.closest( '[data-zg-role="get-product"]' ).trigger( 'UpdateProductInfo', [{ products: [info.status.data.pid] }] );
			}
		}).bind( this ) );

		// ---------------------------------------------------------------------

	};


	/**
	 *
	 * @param {object} options
	 */
	Product.prototype.setOptions = function ( options ) {
		_.extendOwn( this.options, options );

		// cast disabledSkus to array
		if ( _.isString( this.options.disabledSkus ) ) {
			this.options.disabledSkus.split( ',' );
		}

		// make sure that disabledSkus' items are strings
		if ( _.isArray( this.options.disabledSkus ) ) {
			this.options.disabledSkus = _.map( this.options.disabledSkus, function ( item ) {
				return '' + item;
			} );
		}

		// disableOptions should be either boolean or Array
		if ( _.isString( this.options.disableOptions ) ) {
			this.options.disableOptions.split( ',' );
		}

		if ( _.isArray( this.options.disableOptions ) ) {
			this.options.disableOptions = _.map( this.options.disableOptions, function ( item ) {
				if ( !isNaN( item ) ) {
					item = +item;
				}

				return item;
			} );
		}
	};


	/**
	 * Invoked after the product script has been initialized and the event handlers are set.
	 * It will set up the product info for the script updating the seo content, selected options,
	 * ...
	 */

	/**
	 * @method setProductInfo
	 * @fires document#zg.product.ready The product was ready (gallery is initialized, options are configured, availability is check)
	 */

	Product.prototype.setProductInfo = function ( product ) {
		if ( product ) {
			this.product = product;

			this.availability        = null;
			this.isAvailable         = false;
			this.availabilityChecked = false;

			this.updateSEO();

			// initialize the product gallery
			zgGalleries(
				this.$element,
				this.options,
				{
					productName: product.name
				}
			);


			this.$grid = $( this.options.gridOptions, this.$element );
			this.createGrid();
			this.$listOptions = $( this.options.singleOption, this.$element );
			$( document ).trigger( 'zg.product.ready', [this] );
		} else if ( DEBUG ) {
			console.warn( 'setProductInfo: No product object provided' );
		}
	};


	/**
	 *
	 *
	 */
	Product.prototype.updateAvailability = function ( quantity, cartQuantity ) {

		
		var availability;
		var remaining;
		var selectedQuantity;

		if ( this.product ) {
			quantity     = _.isNumber( quantity ) ? quantity : 1;
			var lastCart = window.getLastCart ? window.getLastCart() : null;
			var products_in_cart = lastCart.products_in_cart;
			$('[data-zg-role="single-option"]').each(function(){
				var maxQuantity = $(this).data('quantity');
				var cartQuantity = 0;
				for(var index in products_in_cart){
					if(products_in_cart[index].sku == $(this).data('sku')){
						cartQuantity = products_in_cart[index].quantity;
					}
				}
				$(this).find('[data-quantity]').attr('max',maxQuantity-cartQuantity);
				
				
			});
		
		}
	};


	/**
	 *
	 * @param {boolean} [isAvailable]
	 */
	Product.prototype.updateButton = function (  ) {
		var isAvailable = false;
		var countProductToAdd = 0;
		$('[data-zg-role="single-option"]').each(function(){
			countProductToAdd += $(this).find('[data-quantity]').val();
		});
		if(countProductToAdd > 0){
			isAvailable = true;
		}
		// enable or disable Add to cart button
		$( this.options.elementAddToCart, this.$element ).prop( 'disabled', !isAvailable ); //QUI ABILITA
		//$( this.options.elementAddToCart, this.$element ).prop( 'disabled', false ); 
		
	};



	/**
	 *
	 *
	 */
	Product.prototype.updateSEO = function () {
		var uriStatus;
		var useReplace;

		if ( this.options.updateUri && this.product.seo ) {
			// Get the current history status
			uriStatus = (( $.uriMgr( { action: 'getStatus' } ) || {} ).status || {} );
			// If the current status was triggered by Product and the pid is equal to the current
			// one we use 'replace' instead of 'load'
			useReplace = !!(
				uriStatus.data &&
				uriStatus.data.trigger === 'Product' &&
				uriStatus.data.pid === this.product.id
			);

			// set up the SEO url
			if ( this.product.url ) {
				$.uriMgr( {
					// with 'load' if the browser does not support History, the new url will be
					// loaded
					action: useReplace ? 'replace' : 'load',
					url:    this.product.url,
					title:  this.product.seo.title,

					//store information to change the current product based on the browser history
					data: {
						trigger: 'Product',
						pid:     this.product.id
					}
				} );
			}

			// setup new product page
			if ( this.product.seo.title ) {
				document.title = this.product.seo.title;
			}

			if ( this.product.seo.meta_description ) {
				$( 'meta[name=description]' ).remove();
				$( 'head' ).append( '<meta name="description" content="' + this.product.seo.meta_description + '">' );
			}
		}
	};


	// PRODUCT PLUGIN DEFINITION
	// =========================

	function Plugin ( option, product ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.product' );
			var options = $.extend(
				{},
				Product.DEFAULTS,
				window.ZG_CONFIG || {},
				$this.data(),
				typeof option === 'object' && option
			);

			product = product || options.product || null;
			delete( options.product );

			if ( !data ) {
				$this.data( 'zg.product', (data = new Product( this, options )) );
			} else if ( typeof option === 'object' ) {
				data.setOptions( options );
			}

			if ( product ) {
				data.setProductInfo( product );
			}
		} );
	}

	$.fn.zg_product             = Plugin;
	$.fn.zg_product.Constructor = Product;



	/**
	 * @method document
	 * @listen document#zg.getProductInfo.productCreated  When product rendered by handlebars (like
	 *     in category page) start the plugin
	 */
	$( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
		var $element = $( element );
		var linkedOptions;

		// if its a product container we initialize the scripts for the linked products
		if ( product && product.attributes && product.attributes.isContainer && product.linked_products ) {
			// several products per page. The linked products shouldn't update the url.
			linkedOptions = $.extend( {}, options || {}, { updateUri: false } );

			for ( var i = 0; i < product.linked_products.length; i++ ) {
				Plugin.call(
					$( element ).find( selector + '[data-id="' + product.linked_products[i].id + '"]' ),
					linkedOptions,
					product.linked_products[i]
				);
			}
		}

		// normal product / product container
		if ( $element.filter( selector ).length ) {
			$element = $element.filter( selector );
		} else {
			$element = $element.find( selector + '[data-id="' + product.id + '"]' );
		}

		Plugin.call( $element, options, product );
	} );

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));

