// Add an event listener
document.addEventListener("ajax-cart-changed", function(event) {
	setTimeout(function(){
		$("#fakeCart").html( "<ul>" + $("ul[data-zg-role='list-items-container']").html() + "</ul>" );
	}, 1000);
});
document.addEventListener("ajax-wishlist-changed", function(event) {

});
document.addEventListener("zg.getProductListInfo.success", function(event) {

});
document.addEventListener("category-filled", function(event) {

	//setTimeout(function(){
		//$("#products-container .category-list").append("<div>PROVA</div>");
	//}, 1000);
});

// Create the event
//var upgradeAjaxCart = new CustomEvent("ajax-cart-changed", { "detail": "Ajax cart is changed, upgrade the fake ajax-cart!" });
//var upgradeAjaxWishlist = new CustomEvent("ajax-wishlist-changed", { "detail": "Ajax wishlist is changed, upgrade the fake ajax-wishlist!" });
//var categoryFilled = new CustomEvent("category-filled", { "detail": "Filled the category page with products" });

var upgradeAjaxCart = document.createEvent("CustomEvent");
upgradeAjaxCart.initCustomEvent('ajax-cart-changed', false, false, {
    'detail': "Ajax cart is changed, upgrade the fake ajax-cart!"
});
var upgradeAjaxWishlist = document.createEvent("CustomEvent");
upgradeAjaxWishlist.initCustomEvent('ajax-cart-changed', false, false, {
    'detail': "Ajax wishlist is changed, upgrade the fake ajax-wishlist!"
});
var categoryFilled = document.createEvent("CustomEvent");
categoryFilled.initCustomEvent('ajax-cart-changed', false, false, {
    'detail': "Filled the category page with products"
});



// Dispatch/Trigger/Fire the event
//document.dispatchEvent(upgradeAjaxCart);
//document.dispatchEvent(upgradeAjaxWishlist);
//document.dispatchEvent(categoryFilled);



//
// Utility
//

var m = window.matchMedia("(max-width: 767px)"); 													// mobile  media query
var t = window.matchMedia("(min-width: 768px) and (max-width: 1023px)"); 	// tablet  media query
var d = window.matchMedia("(min-width: 1024px)"); 												// desktop media query
var l = window.matchMedia("(orientation: landscape)"); 										// landscape media query
var p = window.matchMedia("(orientation: portrait)"); 										// portrait media query

function getSize(){ // return ("mobile", "tablet" or "desktop") that's the size of the view
	var result = "unknown";
	if( $("body").hasClass("size_mobile") ){
		result="mobile";
	}
	else {
		if( $("body").hasClass("size_tablet") ){
			result="tablet";
		}
		else {
			if( $("body").hasClass("size_desktop") ){
				result="desktop";
			}
		}
	}
	return result;
}

function wichSize(m, t, d, l, p) { 	// Evaluate media query and mark the body tag
	$("body").removeClass("size_mobile").removeClass("size_tablet").removeClass("size_desktop").removeClass("landscape").removeClass("portrait");
	if (m.matches) { 				// If media query matches
		$("body").addClass("size_mobile");
	}
	else{
		if (t.matches) { 			// If media query matches
			$("body").addClass("size_tablet");
		}
		else{
			if (d.matches) { 		// If media query matches
				$("body").addClass("size_desktop");
			}
		}
	}
	if (l.matches){					// If media query matches
		$("body").addClass("landscape");
	}
	else{
		if (p.matches){				// If media query matches
			$("body").addClass("portrait");
		}
	}
}

wichSize(m, t, d, l, p); 		// Call listener function at run time
$(window).resize(function(){ 	// Attach listener function on state changes
	wichSize(m, t, d, l, p);
});

// Set the lang from the langswitcher in the header
function setLang(lang){
	if (typeof lang !== 'undefined') {
		var targeturl = $('.navbar-lang .dropdown-menu li a[hreflang="' + lang.toLowerCase() + '"]').attr("href");
		window.location.href = targeturl;
	}
	return 0;
}

function setLang2(datalang){
	var targeturl = $('.navbar-lang .dropdown-menu li a[data-lang="' + datalang.toLowerCase() + '"]').attr("href");
	window.location.href = targeturl;
	return 0;
}

//
// /Utility
//

//Is Elemment in viewport
function isElementInViewport(el){
	if (typeof jQuery === "function" && el instanceof jQuery) { el = el[0]; }
	var rect = el.getBoundingClientRect();
	var result = "NONE";
	if(
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
		rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
	){ result = "TRUE"; }
	else{ result = whereIsElementOutViewport(el); }
	return result;
}
function whereIsElementOutViewport(el){
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	var rect = el.getBoundingClientRect();
	var result = "NONE";
	if( rect.top < 0 ){ result = "UP"; }
	else{ result = "DOWN"; }
	return result;
}

//
// Header
//

// LANG
$(".othermenu .lang-item").click(function(event){
	event.preventDefault();
	if( !$(this).hasClass("active") ){
		setLang( $(this).attr("hreflang") );
	}
	return;
});

//
// /Header
//

//
// Footer
//

// MENUS
$("#site_footer .link-block p").click(function(event){
	event.preventDefault();
	if( !$(this).parent().hasClass("opened") ){
		$("#site_footer .link-block p").parent().removeClass("opened");
		$(this).parent().addClass("opened");
	}
	else{
		$("#site_footer .link-block p").parent().removeClass("opened");
	}
	return;
});

if( getSize() != "desktop" ){
	$("#site_footer .newsletter-block p").click(function(){
		if( $(this).parent().hasClass("opened") ){
			$(this).parent().removeClass("opened");
		}
		else{
			$(this).parent().addClass("opened");
		}
	});
}

// LANG
$("#site_footer .lang-item, #navbar .lang-item").click(function(event){
	event.preventDefault();
	if( !$(this).hasClass("active") ){
		setLang2( $(this).attr("data-lang") );
	}
	return;
});

// SEARCH
var search_clicked; // KEEP DATA OF CLICKED LOCATION
$( document ).ready(function(){
	$("#product-finder .form-control").click(function () {
		search_clicked = true;
		setTimeout(function () {
			search_clicked = false;
		}, 200);
	})
	$("#openSearch").click(function(){
		if( $("#openSearch").parent().hasClass("opened") ){
			$("#openSearch").parent().removeClass("opened");
			$("*").off('click.closeSearch');
		}
		else{
			$("#openSearch").parent().addClass("opened");
			search_clicked = true;
			setTimeout(function () {
				search_clicked = false;
			}, 200);
			$("*").on('click.closeSearch', function() {
				if (search_clicked != true) {
					//CODE OFR CLICK OUTSIDE SEARCH
					$("#openSearch").parent().removeClass("opened");
					$("*").off('click.closeSearch');
				}
			});

		}
	});
});

//
// /Footer
//

//
// Home
//

$( document ).ready(function(){
	if( $("body").data("template") == "homeMgr.html" ){
		if( $("body").hasClass("size_mobile") ){
			
		}
		if( $("body").hasClass("size_desktop") ){
			
			$('#provaSlider').slick({
				arrows: false,
				centerMode: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				//autoplay: true,
				infinite: true,
				//autoplaySpeed: 3000
			});
		}
	}
	else{
		
	}
});
//
// /Home
//

//
// Product
//

$(".size-changer").bind("DOMSubtreeModified",function(){


	$('.fake-size-selector-input span').removeClass("active");
	//$('.fake-size-selector-input span:contains("' + $(".size-changer").text() + '")').addClass("active");

	$('.fake-size-selector-input span').filter(function() {
		return $(this).text() === $(".size-changer").text();
	}).addClass("active");
});
/*
$('[data-zg-role="add-to-cart"]').click(function(){

	dataLayer.push({
		'event': 'addToCart',
		'ecommerce': {
			'currencyCode': 'EUR',
			'add': {                                // 'add' actionFieldObject measures.
				'products': [{                        //  adding a product to a shopping cart.
					'name': 'Triblend Android T-Shirt',
					'id': '12345',
					'price': '15.25',
					'brand': 'Google',
					'category': 'Apparel',
					'variant': 'Gray',
					'quantity': 1
				}]
			}
		}
	});
});
*/
var test = function(item){  }
var addToCartDataLayerPush = function(currencyCode, name, id, price, brand, category, variant){
	dataLayer.push({
		'event': 'addToCart',
		'ecommerce': {
			'currencyCode': currencyCode,
			'add': {                                // 'add' actionFieldObject measures.
				'products': [{                        //  adding a product to a shopping cart.
					'name': name,
					'id': id,
					'price': price,
					'brand': brand,
					'category': category,
					'variant': variant,
					'quantity': 1
				}]
			}
		}
	});
};

var raiseEvent = function(eventType){
		dataLayer.push({'event': eventType});
};

var newAddToCartDataLayerPush = function(event, objItem){

	
	variant = $(event.target).attr("data-selected-variant");
	objItem = JSON.parse(objItem);
	var categories = '';
	for (i = 0; i < objItem.categories.length; i++) {
  	categories += objItem.categories[i].label + "/";
		categories += "pippo/";
	}
	dataLayer.push({
		'event': 'addToCart',
		'ecommerce': {
			'currencyCode': objItem.price.currency,
			'add': {
				'listType': 'pdp',
				'products': [{
					'name': objItem.name,
					'id': objItem.code,
					'price': objItem.price.sell,
					'brand': objItem.brand,
					'category': categories,
					'variant': variant,
					'quantity': 1
				}]
			}
		}
	});
}

var sizeselect_clicked = false;
$( document ).ready(function() {

	$('.product-sizes .btn-size-product-options').click(function(event){
		event.preventDefault();
		var id = $(this).attr('data-key');
		var optionName = $(this).html();
		//$('.fake-size-selector-input span').removeClass("active");
		//$(this).addClass("active");
		//$('.size-selector select').find('option[value=' + id + ']').prop('selected', true);
		//$('.size-selector select').change(); //trigger( "change" );

		$('button[data-zg-role="add-to-cart"]').addClass("selected");
		$('button[data-zg-role="add-to-cart"]').removeClass("adding").attr("data-selected-variant", optionName);
	});

	if( !$('.product-sizes .btn-size-product-options.active').length ){ //nulla di selezionato
		$('button[data-zg-role="add-to-cart"]').removeClass("selected");
		//$('.size-changer').fadeOut(0);
		//$('.first-size-selector').fadeIn(0);
	}
	else{ //c'e' una selzione
		var size = $('.size-selector-input').find(":selected").text();

		//$('.size-changer').fadeIn(0).html(size);
		//$('.size-changer').parent().addClass("selected");

		//$('button[data-zg-role="add-to-cart"]').fadeIn(0);
		/*
		$('.fake-size-selector').fadeOut(0);
		$('.first-size-selector').fadeOut(0);
		$('.size-changer').click(function(event){
			event.preventDefault();
			$('.fake-size-selector').fadeIn(0);
			$('.first-size-selector').fadeOut(0);
			$('button[data-zg-role="add-to-cart"]').fadeOut(0);
		});
		*/
	}

	/*
	$('.first-size-selector h6').click(function(){
		if( $('.first-size-selector h6').hasClass("opened") ){
			$('.first-size-selector h6').removeClass("opened");
			$('.fake-size-selector').fadeOut(0);
			$('.first-size-selector').fadeIn(0);
			$('button[data-zg-role="add-to-cart"]').fadeIn(0);
			$('.size-changer').fadeIn(0);
		 }
		else{
			$('.first-size-selector h6').addClass("opened");
			$('.fake-size-selector').fadeIn(0);
			$('.first-size-selector').fadeOut(0);
			$('button[data-zg-role="add-to-cart"]').fadeOut(0);
			$('.size-changer').fadeOut(0);
			$('.size-changer').parent().removeClass("selected");
		}
	});
	*/

	/*
	$(".fake-size-selector h6").click(function(){



			if( $('.size-selector-input').val() == "" ){ //nulla di selezionato
				$('.first-size-selector h6').removeClass("opened");
				$('.fake-size-selector').fadeOut(0);
				$('.first-size-selector').fadeIn(0);
				$('button[data-zg-role="add-to-cart"]').fadeOut(0);
				$('.size-changer').fadeIn(0);
			}
			else{ //c'e' una selzione
				var size = $('.size-selector-input').find(":selected").text();

				$('.size-changer').fadeIn(0).html(size);
				$('.size-changer').parent().addClass("selected");

				$('button[data-zg-role="add-to-cart"]').fadeIn(0);

				$('.fake-size-selector').fadeOut(0);
				$('.first-size-selector').fadeOut(0);
				$('.size-changer').click(function(event){
					event.preventDefault();
					$('.fake-size-selector').fadeIn(0);
					$('.first-size-selector').fadeOut(0);
					$('button[data-zg-role="add-to-cart"]').fadeOut(0);
				});
			}


	});
	*/

	/*
	$('.size-selector-input').change(function(){
		var size = $(this).find(":selected").text();

		$('.size-changer').fadeIn(0).html(size);
		$('.size-changer').parent().addClass("selected");

		$('button[data-zg-role="add-to-cart"]').addClass("selected").fadeIn(0);
		//.html( 'AGGIUNGI AL CARRELLO <i class="fa fa-long-arrow-right float-right aria-hidden="true"></i>' );

		$('.fake-size-selector').fadeOut(0);
		$('.first-size-selector').fadeOut(0);
		$('.size-changer').click(function(event){
			event.preventDefault();
			$('.fake-size-selector').fadeIn(0);
			$('.first-size-selector').fadeOut(0);
			$('button[data-zg-role="add-to-cart"]').fadeOut(0);

			$('.size-changer').fadeOut(0);
			$('.size-changer').parent().removeClass("selected");
		});
	});
	*/
});

//SLIDESHOW
$(document).ready(function(){
	if( $('body').attr('data-template') == "productMgr.html" ){ //Solo in pagina prodotto

		if( $("body").hasClass("size_desktop") ){

			//Main pictures Slideshow
			$('#zoomMain img[data-lazy]').each(
				function(){
					$(this).attr("src", $(this).attr("data-lazy"));
				}
			);

			//Related Slideshow
			$('.suggestedProducts').slick({
				arrows: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 3,
				slidesToScroll: 1,
				mobileFirst: false,
				lazyLoad: 'progressive'
			});
		}
		else{

			//Main pictures Slideshow
			$('#zoomMain').on('init', function(event, slick, currentSlide, nextSlide){
				$('#zoomMain .slick-slide').css('height', 'auto');
			});
			$('#zoomMain').slick({
				arrows: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 1,
				slidesToScroll: 1,
				mobileFirst: true,
				lazyLoad: 'progressive'
			});

			//Related Slideshow
			$('.suggestedProducts').slick({
				arrows: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 1,
				slidesToScroll: 1,
				mobileFirst: true,
				lazyLoad: 'ondemand'
			});

		}
	}
});

// // FIXED/STICKY Size selector

function setDimensions(){
	var tempWidth = $('article').innerWidth();
	var tempHeight = $('section.images-section').height();
	//$('section.data-section').width( ( (tempWidth / 2) -51 ) );
	$('article').height( tempHeight );
	if($('.product-grid') && $('section.data-section').attr("style") != undefined){
		//50+data-section header description-grid
		var maxH=$('.data-section').outerHeight( true );
		var occupedHeight = 100 + $('.data-section').find('header').outerHeight( true ) + $('.data-section').find('.description-grid').outerHeight( true );
		var gridH= $('.product-grid').outerHeight( true );
		var remainingH =maxH-occupedHeight
		if(remainingH <gridH){
			$('.product-grid').height(remainingH);
			$('.product-grid').css('overflow','auto');
		}
	}
}

// Function for HANDLER
function onVisibilityChange(el, callback) { //valuta se el e' in viewport, se e' sopra o se e' sotto
	var old_visible;
	return function () {
		var visible = isElementInViewport(el);
		if (visible != old_visible ) {
			old_visible = visible;
			if (typeof callback == 'function') { // se la sua visibilita' cambia lascia valutare alla callback il dafarsi
				callback(visible);
			}
		}
	};
}

// Event HANDLER
/*
var productHandlerDesktop = onVisibilityChange( $('section.marker-section'), function(visibility) {
	if(visibility == "UP" || visibility == "TRUE" ){
		//if( !$('section.title-section').hasClass('styleStatic') ){ $('section.title-section').addClass('styleStatic').removeClass('styleFixed'); }
		//if( !$('section.data-section').hasClass('styleStatic') ){ $('section.data-section').addClass('styleStatic').removeClass('styleFixed'); }

	}
	if(visibility == "DOWN"){
		//if( !$('section.title-section').hasClass('styleFixed') ){ $('section.title-section').addClass('styleFixed').removeClass('styleStatic'); }
		//if( !$('section.data-section').hasClass('styleFixed') ){ $('section.data-section').addClass('styleFixed').removeClass('styleStatic'); }

	}
});

var productHandlerMobile = onVisibilityChange( $('hr.point'), function(visibility) {
	if(visibility == "UP" || visibility == "TRUE" ){
		if( !$('.option-selector').hasClass('styleStatic') ){ $('.option-selector').addClass('styleStatic').removeClass('styleFixed'); }
	}
	if(visibility == "DOWN"){
		if( !$('.option-selector').hasClass('styleFixed') ){ $('.option-selector').addClass('styleFixed').removeClass('styleStatic'); }
	}
});

if( $('body').attr('data-template') == "productMgr.html" ){ //Solo in pagina prodotto
	if( $("body").hasClass("size_desktop") ){
		$(window).on('DOMContentLoaded load resize scroll', setDimensions);
		$(window).on('DOMContentLoaded load resize scroll', productHandlerDesktop); //ad ogni evento di cambiamento pagina lancio HANDLER che esegue onVisibilityChang con due parametri: elemento e callback
	}
	else{
		$(window).on('DOMContentLoaded load resize scroll', productHandlerMobile); //ad ogni evento di cambiamento pagina lancio HANDLER che esegue onVisibilityChang con due parametri: elemento e callback
	}
}
*/

//
// /Product
//

//
// CATEGORY
//

//jQuery Event

function categoryElementSizer(){

	var height = 0;
	var width  = "33.333333";
	var width2 = "66.666666";
	if( $('#products-container').attr('data-pageversion') == "capsule" ){
		width  = "50";
		width2 = "100";
	}
	var bossWidth = $(".category-list").width();
	if( specialProductNumber != 1 ){
		height = $(".category-list .productNumber-1").height();
	}
	else{
		height = $(".category-list .productNumber-2").height();
	}
	var styles = {
		position : "absolute",
		bottom: "0",
		width: "100%"
	};

	$(".featured-products").height( ( height + 2 ) );
	//$("#quickbuy_content").css( "width", bossWidth + "px" ).height( height );
	//$("[class*=productNumber-]").css( "width", width + "%" );
	if( $('#products-container').attr('data-pageversion') != "capsule" ){
		$("[class*=productNumber-]").height( height );
	}

	var i;
	for (i = 1; i < 21; i++) { //Setta venti prodotti con stile grande
		$(".productNumber-" + (specialProductNumber * i ) ).css( "width", width2 + "%" ).addClass("wider");
		if( $('#products-container').attr('data-pageversion') != "capsule" ){
			$(".productNumber-" + (specialProductNumber * i ) ).height( (height * 2) + 2 ).find(".panel-body").css(styles).closest(".product-preview").next().css("margin-top", "-" + (height + 2) + "px");
		}
	}

}


function quickbuy_sizeSelect(key){
				//disattiva tutti gli span
				$('#quickbuy_content .fake-size-selector-input span').removeClass('active');
				//attiva lo span corretto
				$('#quickbuy_content .fake-size-selector-input span[data-key="' + key + '"]').addClass('active');
				//seleziona la taglia
				$('#quickbuy_content .size-selector .size-selector-input option[value="' + key + '"]').prop("selected", true);
				$('button[data-zg-role="add-to-cart"]').addClass("selected");
				$('#quickbuy_content .size-selector .size-selector-input').trigger('change');
			}

$( window ).on( "load", function() {
	if( $('body').attr('data-template') == "categoryMgr.html" || $('body').attr('data-template') == "searchMgr.html" ){ //Solo in pagina categoria

		if( $("body").hasClass("size_desktop") ){
			//$(window).on( "categoryPage.rendered resize scroll", function(event) { categoryElementSizer(); });
			/*
			setTimeout(() => {
				$(window).on( "categoryPage.rendered resize", function(event) { categoryElementSizer(); });
			}, 1000);
			*/

			$(document).on('click', '.modal-backdrop', function () {
				$("#quickbuy_content").modal('toggle');
			});

			$(window).on( "zg.product.addedToCart", function() {
				if( $('body').attr('data-template') == "categoryMgr.html" || $('body').attr('data-template') == "searchMgr.html" ){
					$("#quickbuy_content").hide(0);
					$("#quickbuy_content button.close").trigger("click");
				}
			});

		}
		else{

		}
	}
});

//
// /Category
//

//
// Cart
//

if ( $('hr.point').length ) {
	var cartHandler = onVisibilityChange( $('hr.point'), function(visibility) {
		if(visibility == "UP" || visibility == "TRUE" ){
			if( !$('.toFixedMobile').hasClass('styleStatic') ){ $('.toFixedMobile').addClass('styleStatic').removeClass('styleFixed'); }
		}
		if(visibility == "DOWN"){
			if( !$('.toFixedMobile').hasClass('styleFixed') ){ $('.toFixedMobile').addClass('styleFixed').removeClass('styleStatic'); }
		}
	});
}

if( $('body').attr('data-template') == "cartMgr.html" ){ //Solo in pagina carrello
	$(window).on('DOMContentLoaded load resize scroll', cartHandler); //ad ogni evento di cambiamento pagina lancio HANDLER che esegue onVisibilityChang con due parametri: elemento e callback

	$(document).ready(function() {
		var leftColumnHeight = $(".mobile-cart td.left_col").first().height();
		var productNameWidth = $(".mobile-cart tr .lead").first().width();
		$(".mobile-cart td.right_col").css({"height": leftColumnHeight});
		$(".mobile-cart tr .productName").css({"width": productNameWidth, "white-space": "nowrap"});

		/* GESTIONE DEL PLACEHOLDER E DEL BOTTONE DEL CUPON DESKTOP */
		var text = $("body.size_desktop input#promo_code").attr("placeholder");
		$("body.size_desktop input#promo_code").focus(function(){
			this.placeholder = '';
			$(this).parent().addClass("active");
		}).blur(function(){
			this.placeholder = text;
			if( $(this).val().length < 1 ){
				$(this).parent().removeClass("active");
			}
		}).change(function(){

		});

	});
}

//
// /Cart
//
