/**
 *
 * Plugin for Enhanced Ecommerce
 * - 1. Product Impressions
 * - 2. Product Clicks
 * - 3. Add / Remove from Cart
 * - 4. Promotion Impressions
 * - 5. Promotion Clicks
 * What is not included here is present in global/__google_tag_manager.html
 */

(function ( $ ) {
    'use strict';

    var dataLayer = window.dataLayer || [],
        contProductImpressions = 0;

    function parseCategoryNames( categories ){
        var categoryArray = _.map( categories, function( object ){ return object.name;} );
        return categoryArray.join(' / ');
    }

     /*GA4 REFUND*/ 
     
    $( document ).on( 'zg-refund-event', function ( e,request){

        var products =request.products;
        var options =request.options;
        var items = [];
        var currency ='';
        for(var product in products){
            
            items.push({
                item_id: products[product].item_id,
                item_name: products[product].item_name,
                coupon:  products[product].coupon,
                currency:  products[product].currency,
                discount:  products[product].discount,
                item_brand:  products[product].item_brand,
                item_category:  products[product].item_category,
                item_category2:  products[product].item_category2,
                item_category3:  products[product].item_category3,
                item_category4:  products[product].item_category4,
                item_category5:  products[product].item_category5,
                item_variant:  products[product].item_variant,
                price:  products[product].price,
                quantity:  products[product].quantity,
                index:product

            });
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "refund",
        ecommerce: {
            currency: options.currency,
            transaction_id: options.transaction_id,
            value:options.value,
            coupon: options.coupon,
            shipping: options.shipping,
            items: items
            }
        });
    });
    

     $( document ).on( 'zg.product.addedToWishlist', function ( e, status, productObj, dataSent ) {		
        console.log(productObj);
        let name =$(productObj.$element).data('name');	
        let price =$(productObj.$element).data('price');	
        let brand =$(productObj.$element).data('brand');	
        let categoria =$(productObj.$element).data('categoria');	
        let prodid=$(productObj.$element).data('prodid');


        var categoryname = "";	
        let catArray=categoria.split('|');
        if(catArray.length){		
            $.each(catArray, function() {             
                if( categoryname == '') categoryname = this.replace(/'/ig,' ');         
            });    
        }else{
            categoryname =  categoria;
        }
			
        //var defaultColor = productObj.default_options[0];
        //var colorSelected = productObj.options[4].values[defaultColor].name;
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "add_to_wishlist",
        ecommerce: {
            items: [{
                item_id: prodid || '',
                item_name: name || '',
                affiliation: '',
                coupon: '',
                currency: SGL_JS_CURRENCY,
                discount: 0,
                index: 0,
                item_brand: brand || BRAND,
                item_category: categoryname || window.JS_TRANSLATIONS['category.name'],
                item_list_id: "",
                item_list_name: " ",
                item_variant: "",//colorSelected,
                location_id: "",
                price: price || '',
                quantity: 1
            }]
        }
        });			
				//console.log(dataLayer);
    } );
    /**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
        if( product.price ) {

            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });

            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }
            //console.log(product.price);
            /*GA4 EVENT*/
            var cats =[];
            for(var k in product.categories){
                cats.push(product.categories[k].name)
            }
            var discount =0;
            discount = product.price.to_discount -product.price.sell ;
            if(discount <= 0) {
                discount = 0;
            }
            let promotion_id='';
            let promotion_name='';
            if(typeof product.promotions !== 'undefined' && product.promotions !== null && typeof product.promotions.applied !== 'undefined'){
                promotion_id=Object.keys(product.promotions.applied)[0];
                promotion_name='Promo';
            }
            if(typeof product.promotion !== 'undefined' && product.promotion !== null && typeof product.promotion.applied !== 'undefined'){
                promotion_id=Object.keys(product.promotion.applied)[0];
                promotion_name='Promo';
            }
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                items: [{
                    promotion_id:  promotion_id ,
                    promotion_name: promotion_name,
                    item_id: product.id || '',
                    item_name: product.name || '',
                    affiliation: '',
                    coupon: '',
                    currency: product.price.currency,
                    discount: discount,
                    index: 0,
                    item_brand: product.brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: list,
                    item_list_name: list,
                    item_variant: '',
                    location_id: '',
                    price: product.price.sell,
                    quantity: 1
                    }]
                }
            });
            /*END GA4 EVENT*/

            

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });
        }
    } );

    /**
     * 2. Product Clicks
     *
     * To track the link, be sure that all link tag have the attr data-zg-role="product-link"
     */
    $( document ).on( 'click', '[data-zg-role="product-link"]', function(){

        if( this.attributes.href ) {
            var list;
            var parent = $( this ).closest( '[data-zg-role="product"]' ).data( 'zg.product' );
            var productObj = parent.product;
            var categoryNames = parseCategoryNames( productObj.categories );
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'products': [{
                            'name': productObj.name || '', // Name or ID is required.
                            'id': productObj.id || '',
                            'category': categoryNames || '',
                            'price': productObj.price.sell || ''
                        }]
                    }
                }
            });


            var cats =[];
            for(var k in productObj.categories){
                cats.push(productObj.categories[k].name)
            }
            var discount =0;
            discount = productObj.price.to_discount - productObj.price.sell ;
            if(discount <= 0) {
                discount = 0;
            }
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

            //GA4 related product 
            dataLayer.push({
            event: "select_item",
            ecommerce: {
                items: [{
                    item_id: productObj.id || '',
                    item_name: productObj.name || '',
                    affiliation: '',
                    coupon: '',
                    currency: productObj.price.currency,
                    discount: discount,
                    index: 0,
                    item_brand: productObj.brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: list,
                    item_list_name: list,
                    location_id: "",
                    price: productObj.price.sell || '',
                    quantity: 1
                }]
            }
            });
        }
			//console.log(dataLayer);
    });

    /**
     * 3. Add / Remove from Cart
     *
     * Required event: zg.product.addedToCart
     * Required data: status, productObj, dataSent
     */
    $( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
			
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': productObj.price.currency,
                'add': { // 'add' actionFieldObject measures.
                    'products': [{ //  adding a product to a shopping cart.
                        'name': productObj.name || '',
                        'id': productObj.id || '',
                        'price': productObj.price.sell || '',
                        'brand': productObj.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
												'category': window.JS_TRANSLATIONS['category.name'],
                        'variant': dataSent.sku || '',
                        'quantity': dataSent.quantity || ''
                    }]
                }
            }
        });
        let discount = 0
        if( productObj.price.to_discount > productObj.price.sell)
            discount = productObj.price.to_discount - productObj.price.sell;
        
        let promotion_id ='';
        let promotion_name ='';
        if(typeof productObj.promotions !== 'undefined' && productObj.promotions !== null  && typeof productObj.promotions.applied !== 'undefined' ){
            promotion_id=Object.keys(productObj.promotions.applied)[0];
            promotion_name = 'Promo';
        }
        var categoryname = "";
			
        $.each(productObj.categories, function(key,val) {             
            if( categoryname == '') categoryname = val.label;         
        });    
        var cats =[];
        for(var k in productObj.categories){
            cats.push(productObj.categories[k].name)
        }
            
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [{
                promotion_id:  promotion_id,
                promotion_name: promotion_name,
                item_id: productObj.id || '',
                item_name: productObj.name || '',
                affiliation: '',
                coupon: '',
                currency: productObj.price.currency,
                discount: discount,
                index: 0,
                item_brand: productObj.brand || BRAND,
                item_category: cats[0] || '',
                item_category2: cats[1] || '',
                item_category3: cats[2] || '',
                item_category4: cats[3] || '',
                item_category5: cats[4] || '',
                item_list_id: "",
                item_list_name: " ",
                item_variant: "",
                location_id: "",
                price: productObj.price.sell || '',
                quantity: dataSent.quantity || ''
            }]
        }
        });
				//console.log(dataLayer);
    } );
    /**
     * Required event: zg.product.removedFromCart
     * Required data: type, status, data
     */
    $( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

        if( type === 'cart' ){
            // Measure the removal of a product from a shopping cart.
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [{ //  removing a product to a shopping cart.
                            'id': data.product_id || '',
                            'variant': data.sku || '',
                            'quantity': data.quantity || ''
                        }]
                    }
                }
            });
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
                items: [{
                    item_id: data.product_id || '',
                    item_name: data.name || '',
                    affiliation: "",
                    coupon: "",
                    currency:  SGL_JS_CURRENCY,
                    discount: 0,
                    index: 0,
                    item_brand: data.brand || BRAND,
                    item_category: window.JS_TRANSLATIONS['category.name'] || '',
                    item_list_id: "",
                    item_list_name: " ",
                    item_variant: "",
                    location_id: "",
                    price: data.price,
                    quantity:  data.quantity || ''
                }]
            }
            });
        }

    } );

    /**
     * For Cart page
     * When a product is removed from cart we are submitting a form. Also when we submitting a form we should trigger zg.product.removedFromCart
     */
    $( document ).on( 'submit', '[data-zg-role="list-item-remove"]', function () {
        var $this = $(this),
            data  = $this.data();

        var dataToSend = {
            'product_id': 	data.id || 0,
            'sku': 					data.sku || 0,
            'name': 	data.name || '',
            'price': 					data.price || 0,
            'quantity': 		data.quantity || 1
        };

        $( document ).trigger( 'zg.product.removedFromCart', ['cart', null, dataToSend] );
    } );


    
     /**
     * 4. Promotion Impressions
     *
     * To track the promo present in page, be sure that all promo div have the attr data-zg-role="promotion"
     */
    //$( document ).on('each', '[data-zg-role="view_promotion"]', function( index, element ) {

        /*dataLayer.push({
            'ecommerce': {
                'promoView': {
                    'promotions': [
                        {
                            'id': element.dataset.promoId || '', // Name or ID is required.
                            'name': element.dataset.promoName || '',
                            'position': element.dataset.promoPosition || ''
                        }]
                }
            }
        });
*/
/*$('[data-zg-role="view_promotions"]').click(function(){
    let product = $(this).data('product');
    let promotion_id =Object.keys(product.promotions.applied)[0];
    //console.log(product);
    var cats =[];
    for(var k in product.categories){
        cats.push(product.categories[k].name)
    }
    let discount = 0
    if( product.price.to_discount > product.price.sell)
        discount = product.price.to_discount - product.price.sell;
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
    event: "select_promotion",
    ecommerce: {
        promotion_id: promotion_id || 'Discount',
        promotion_name: product.promotions.discount || 'Discount',
        items: [
        {
        item_id: product.id || '',
        item_name: product.name || '',
        affiliation: '',
        coupon: "",
        discount: discount,
        index: 0,
       item_brand: product.brand || BRAND, 
        item_category: cats[0] || '',
        item_category2: cats[1] || '',
        item_category3: cats[2] || '',
        item_category4: cats[3] || '',
        item_category5: cats[4] || '',
        item_list_id: "",
        item_list_name: "",
        item_variant: "",
        location_id: "",
        price: product.price.sell,
        quantity: 1
        }
        ]
    }
    });
});*/
 $( document ).on( 'zg.view_item.product', function ( item ) {
     //console.log("VIEW ITEM");
     //console.log(item);
 });
$( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
    if(typeof product.promotions !== 'undefined' && product.promotions !== null  && product.promotions.discount != 0){
        let promotion_id='';
        if(typeof product.promotions !== 'undefined' && product.promotions !== null  && typeof product.promotions.applied !== 'undefined')
            promotion_id=Object.keys(product.promotions.applied)[0];
        let discount = 0
        if( product.price.to_discount > product.price.sell)
            discount = product.price.to_discount - product.price.sell;
        //console.log(product);
        var cats =[];
        for(var k in product.categories){
            cats.push(product.categories[k].name)
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "view_promotion",
            ecommerce: {
                promotion_id:  promotion_id || 'Discount',
                promotion_name: 'Promo',
                items: [
                {
                item_id: product.id || '',
                item_name: product.name || '',
                affiliation: '',
                coupon: "",
                currency: SGL_JS_CURRENCY,
                discount: discount,
                index: 0,
                item_brand: product.brand || BRAND, 
                item_category: cats[0] || '',
                item_category2: cats[1] || '',
                item_category3: cats[2] || '',
                item_category4: cats[3] || '',
                item_category5: cats[4] || '',
                item_list_id: "",
                item_list_name: "",
                item_variant: "",
                location_id: "",
                price: product.price.sell,
                promotion_id:  promotion_id || 'Discount',
                promotion_name: 'Promo',
                quantity: 1
                }
                ]
            }
            });
        }else if(typeof product.promotion !== 'undefined' && product.promotion.discount != 0){
            //console.log(product);
            var cats =[];
                for(var k in product.categories){
                    cats.push(product.categories[k].name)
                }
            let promotion_id='';
            if(typeof product.promotion !== 'undefined' && typeof product.promotion.applied !== 'undefined')
                promotion_id=Object.keys(product.promotions.applied)[0];
            let discount = 0
            if( product.price.to_discount > product.price.sell)
                discount = product.price.to_discount - product.price.sell;
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "view_promotion",
                ecommerce: {
                    promotion_id:  promotion_id || 'Discount',
                    promotion_name: 'Promo',
                    items: [
                    {
                    item_id: product.id || '',
                    item_name: product.name || '',
                    affiliation: '',
                    coupon: "",
                    currency: SGL_JS_CURRENCY,
                    discount: discount,
                    index: 0,
                    item_brand: product.brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: "",
                    location_id: "",
                    price: product.price.sell,
                    quantity: 1
                    }
                    ]
            }
            });
        }

    });
    $('[data-zg-role="promotion-cart"]').each( function() {  
        let dataDiscount = $(this).data('discount').split(';');

        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "select_promotion",
            ecommerce: {
                promotion_id:  dataDiscount[0] || 'Discount',
                promotion_name: dataDiscount[1] || 'Discount',
                items: [/*
                {
                item_id: product.id || '',
                item_name: product.name || '',
                affiliation: '',
                coupon: "",
                currency: "",
                discount: '',
                index: 0,
                item_brand: product.brand || BRAND, 
                item_category: cats[0] || '',
                item_category2: cats[1] || '',
                item_category3: cats[2] || '',
                item_category4: cats[3] || '',
                item_category5: cats[4] || '',
                item_list_id: "",
                item_list_name: "",
                item_variant: "",
                location_id: "",
                price: product.price.sell,
                quantity: 1
                }*/
                ]
            }
            });
        //console.log(dataDiscount);



    });
    /**
     * 5. Promotion Clicks
     *
     * To track the promo link, be sure that all link tag have the attr data-zg-role="promotion-link"
     * Required attr: data-promo-id="x", data-promo-name="x", data-position='x'
     */
    /*
    $( document ).on( 'click', '[data-zg-role="promotion-link"]', function() {
        if( this.attributes.href ){
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': this.dataset.promoId || '', // Name or ID is required.
                                'name': this.dataset.promoName || '',
                                'position': this.dataset.promoPosition || ''
                            }]
                    }
                }
            });

            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "select_promotion",
            ecommerce: {
                items: [{
                    item_id: this.dataset.promoId || '',
                    item_name: this.dataset.promoName || '',
                    affiliation: "",
                    coupon: "",
                    currency: "",
                    discount: 0,
                    index: this.dataset.promoPosition || '',
                    item_brand: "",
                    item_category: "",
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: "",
                    location_id: "",
                    price: 0,
                    quantity: 1
                }]
            }
            });
        }
    });
    */
    let payment_type = '';
    if($('.page_confirm')){
        $( document ).on( 'zg.selectPayment.paymentReady', function(e,paymentInfo){
            payment_type =paymentInfo;
            
        } );
        $('[data-payment-checkout="payment"]').click(function(){
            if (!cart_items || cart_items === undefined ){
                var cart_items = []
            }
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "add_payment_info",
            ecommerce: {
                currency: SGL_JS_CURRENCY,
                value: SGL_JS_TOTAL_SUM,
                coupon: "",
                payment_type: payment_type,
                items: cart_items || []
            }
            });
        });
        $( document ).on( 'zg.selectShipping.shippingReady',function(e,data,val) {
            if (!cart_items || cart_items === undefined ){
                var cart_items = []
            }

            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "add_shipping_info",
            ecommerce: {
                currency: val.currency,
                value: val.currency,
                coupon: "",
                shipping_tier: val.name,
                items: cart_items || []
                }
            });

        });
    }
    

}( jQuery ));
