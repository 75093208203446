/* ============================================================
 * zg-utils.js
 *
 * @author: Botond Szasz   <bszasz@zerogrey.com>
 * @author: David Pocina   <dpocina[at]zerogrey[dot]com>
 * @author: Miguel Sanchez <msanchez[at]zerogrey[dot]com>
 *
 * ============================================================ */

// In case we forget to take out console statements.
// IE becomes very unhappy when we forget.
// Let's not make IE unhappy
(function () {
	'use strict';

	var method;
	var noop = function () {};
	var methods = [
			'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed',
			'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline',
			'timelineEnd', 'timeStamp', 'trace', 'warn'
		];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while ( length-- ) {
		method = methods[length];

		// Only stub undefined methods.
		if ( !console[method] ) {
			console[method] = noop;
		}
	}
}());


(function () {
	'use strict';

	var root = this;

	var makeUrl = function makeUrl ( params ) {
		var rslt = window.SGL_JS_WEBROOT + '/' + window.SGL_JS_FRONT_CONTROLLER;
		var moduleName = params.module ? params.module : '';
		var managerName = params.manager ? params.manager : moduleName;
		var x;

		if ( 'SGL_UrlParser_ClassicStrategy' === window.SGL_JS_URL_STRATEGY ) {
			if ( rslt.charAt( rslt.length - 1 ) !== '?' ) {
				rslt += '?';
			}

			rslt = rslt + 'moduleName=' + window.escape( moduleName ) + '&managerName=' + window.escape( managerName ) + '&';

			for ( x in params ) {
				if ( (x === 'module') || (x === 'manager') ) {
					continue;
				}

				rslt = rslt + window.escape( x ) + '=' + window.escape( params[x] ) + '&';
			}
		} else {
			if ( rslt[rslt.length - 1] === '/' ) {
				rslt = rslt + window.escape( moduleName ) + '/' + window.escape( managerName ) + '/';
			} else {
				rslt = rslt + '/' + window.escape( moduleName ) + '/' + window.escape( managerName ) + '/';
			}

			for ( x in params ) {
				if ( (x === 'module') || (x === 'manager') ) {
					continue;
				}
                if( window.escape( params[x] ) != 'null' ){
                    rslt = rslt + window.escape( x ) + '/' + window.escape( params[x] ) + '/';
                }
			}
		}

		return rslt;
	};

	root.makeUrl = makeUrl;

}.call( this ));


(function ( $, _ ) { /* global _, zgGetObjectPropertyValue */
	'use strict';

	var root = this;

	/**
	 * Comparison script to sort a normal array, an array of objects or a jQuery array of DOM elements.
	 * For Objects and DOM arrays it is required to provide a property to sort by.
	 *
	 * @param {!Object} primary
	 * @param {?Object} secondary
	 *
	 * Objects structure:
	 *   dir - {number} - if is a positive number sorts ascending, if negative sorts descending. Defaults to 1
	 *   attr - {string} - value to use for sorting. for DOM elements can be a attribute or data-attribute and for
	 *     objects a property
	 *   pattern - {Array} - pattern to use for sorting
	 *   avoidNumbersOnTop - {boolean} - do not sort numbers before text
	 *
	 */
	var zg_sortElements = function zg_sortElements ( primary, secondary ) {
		var dir = primary.dir || 1;

		var defaultPatterns = [
			/* ['3xs', 'xxxs', '2xs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', '2xl', 'xxxl', '3xl'] */
			['3xs', '3XS', 'xxxs', 'XXXS', '2xs', '2XS', 'xxs', 'XXS', 'xs', 'XS', 's', 'S', 'm', 'M', 'l', 'L', 'xl', 'XL', 'xxl', 'XXL', '2xl', '2XL', 'xxxl', 'XXXL', '3xl', '3XL']
		];


		var sortByPattern = function sortByPattern ( aa, bb, pattern ) {
			if ( _.contains( pattern, aa ) && !_.contains( pattern, bb ) ) {
				return -1;
			}

			if ( !_.contains( pattern, aa ) && _.contains( pattern, bb ) ) {
				return 1;
			}

			if ( _.contains( pattern, aa ) && _.contains( pattern, bb ) ) {
				return (($.inArray( aa, pattern ) - $.inArray( bb, pattern )));
			}

			return 0;
		};

		return function ( a, b ) {
			var aa = zgGetObjectPropertyValue( a, primary.attr );
			var bb = zgGetObjectPropertyValue( b, primary.attr );
			var	res = 0;

			if ( aa === bb ) {
				// a and b are equal. try to use the secondary sorting method
				if ( secondary ) {
					var secAr = [a, b].sort( zg_sortElements( secondary ) );
					res = +(secondary.dir || 1) * (zgGetObjectPropertyValue( secAr[0], secondary.attr ) === zgGetObjectPropertyValue( a, secondary.attr ) ? -1 : 1);
				}

				return res;
			}

			if ( aa && _.isNull( bb ) ) {
				return (+dir);
			}

			if ( bb && _.isNull( aa ) ) {
				return -(+dir);
			}

			// try to sort the elements using the user defined pattern
			if ( primary.pattern ) {
				res = sortByPattern( aa, bb, primary.pattern );

				if ( res !== 0 ) {
					return (+dir) * res;
				}
			}

			// try to sort the elements using the predefined pattern
			for ( var i = 0; i < defaultPatterns.length; i++ ) {
				res = sortByPattern( aa, bb, defaultPatterns[i] );

				if ( res !== 0 ) {
					return (+dir) * res;
				}
			}

			// ordering numbers
			if ( !isNaN( aa ) && !isNaN( bb ) ) {
				return ((+dir) * ( (+aa) - (+bb) ));
			}

			if ( !primary.avoidNumbersOnTop ) {
				// numbers should be on top
				if ( !isNaN( aa ) && isNaN( bb ) ) {
					return -(+dir);
				}

				if ( isNaN( aa ) && !isNaN( bb ) ) {
					return (+dir);
				}
			}

			// this function (if available) will sort the elements even if they have non ASCII characters.
			if ( aa && _.isFunction( aa.localeCompare ) ) {
				return ((+dir) * aa.localeCompare( bb ));
			}

			// fallback
			res = 0;

			if (aa < bb) {
				res = -1;
			} else if (aa > bb) {
				res = 1;
			}

			return (+dir * res);
		};
	};
	// Usage:
	// var $elements = $( selector )
	// $elements.sort( zg_sortElements( {
	//		dir: [ 1 | -1 ],
	//		attr: [ "attribute" | null ],
	//		pattern: [array],
	//		avoidNumbersOnTop: [true|false]
	//	} ));
	// $elements.parent().append( $elements );


	/**
	 *
	 *
	 * @param {Object} values
	 * @param {string} [prop]
	 * @param {string} [pattern]
	 *
	 * @returns {Array}
	 */
	var zgSortObjectByProp = function zgSortObjectByProp ( values, prop, pattern ) {
		var key;
		var array = [];

		for ( key in values ) {
			if ( values.hasOwnProperty( key ) ) {
				// include the key as a property and add the object to the array
				values[key].key = key;
				array.push( values[key] );
			}
		}

		array.sort( window.zg_sortElements( {
			attr: prop || 'name',
			avoidNumbersOnTop: true,
			pattern: pattern
		} ) );

		return array;
	};

	root.zg_sortElements = zg_sortElements;
	root.zgSortObjectByProp = zgSortObjectByProp;

}.call( this, jQuery, _ ));


(function ( $ ) {
	'use strict';

	$( function () {
		// Shorthand to print the current page
		$( document ).on( 'click', '[data-role="print"]', function () {
			window.print();
		} );

		// Don't allow the user to click multiple times on those links
		$( '[data-prevent-double-click="true"]' ).on( 'click', function () {
			$( this ).off( 'click' );
			$( this ).on( 'click', function () {
				return false;
			} );
            //When the user is waiting is better hide the body
            $( 'body' ).addClass('loading');
		} );

        // Don't allow the user to multi submit a form
        $( '[data-prevent-double-submit="true"]' ).on( 'submit', function () {
            var $this = $(this);

            if ( $this.data( 'zgValidator' ) ) {
                if ( !$this.data( 'zgValidator' ).validate() ) {
                    return false;
                }
            }

            $(':submit', this).attr('disabled', 'disabled');
            //When the user is waiting is better hide the body
            $( 'body' ).addClass('loading');
        } );

	} );
}( jQuery ));
