/**
 * Some utils for address manager (getAddressById and getAddressList)
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 * @author Fabio Polizzi <fpolizzi[at]kooomo[dot]com>
 *
 */

/**
 * @event document#addressUtils.addressReady The list of addresses of the user are ready to use
 * @type {object}
 * @property {object} Addresses - Send to the event the addresses object (all address of user) received by backend
 */

/* global zgGet, Promise */

(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	var addressPromise;

	/**
	 * create a new 'fetchAddresses' promise
	 *
	 * @param {boolean} [forceRequest]  Force a new request
	 * @returns {Promise}
	 */
	var fetchAddresses = function fetchAddresses ( forceRequest ) {

		if ( !addressPromise || forceRequest ) {
			addressPromise = new Promise( function ( resolve, reject ) {
				zgGet(
					'fetchAddresses',
					null,
					null,
					{
						success: resolve,

						error: function () {
							reject( new Error( 'addressUtils.fetchAddresses - Request Failed' ) );
						}
					}
				);
			} );
		}
		addressPromise.then((res)=>{window.BILLING_COUNTRY = res.data[0].country_id})
		return addressPromise;
	};


	/**
	 * Get Address information from the list by Id
	 *
	 * @param {string|number} addressId       Id of the address to be returned
	 * @param {boolean}       [forceRequest]  Force a new request
	 * @returns {Promise}
	 */
	var getAddressById = function getAddressById ( addressId, forceRequest ) {
		return new Promise( function ( resolve, reject ) {
			if ( addressId ) {
				fetchAddresses( forceRequest ).then(
					function ( addressList ) {
						resolve(
							_.findWhere( addressList.data, {address_id: String( addressId )} )
						);
					},
					reject
				);
			} else {
				reject( new Error( 'addressUtils.getAddressById - Called without addressId' ) );
			}
		} );
	};


	var getCountryOfSelectedBilling = function getCountryOfSelectedBilling(forceRequest) {
		console.log("getCountry")
		return new Promise( function ( resolve, reject ) {
			fetchAddresses( forceRequest ).then(
				function ( addressList ) {
					resolve(
						console.log(addressList.data)						);
					},
					reject
			);
	})}


	/**
	 * trigger an event on success passing the addresslist as parameter
	 *
	 * @param {boolean} [forceRequest] Force a new request
	 * @returns {Promise}
	 */
	var getAddressList = function getAddressList ( forceRequest ) {
		return new Promise( function ( resolve, reject ) {
			fetchAddresses( forceRequest ).then(
				function ( response ) {
					$( document ).trigger( 'addressUtils.addressReady', [response] );
					resolve( response );
				},
				reject
			);
		} );
	};


	/**
	 * Expose the utils function
	 *
	 * @type {{getAddressById: Function, getAddressList: Function}}
	 */
	root.zgAddressUtils = {
		getAddressById: getAddressById,
		getAddressList: getAddressList,
		getCountryOfSelectedBilling: getCountryOfSelectedBilling
	};
}.call( this, jQuery, _ ));