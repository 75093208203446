



// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		// Tooltips
		//$('[data-toggle="tooltip"]').tooltip();

		// Carousel Settings - Home
		$( '.carousel' ).carousel( {} );

        // SWIPE CAROUSEL
        var $carousel = $( '.carousel' );
        if ( $carousel.length ) {
            $carousel.swiperight( function () {
                $( this ).carousel( 'prev' );
            } );
            $carousel.swipeleft( function () {
                $( this ).carousel( 'next' );
            } );
        }
	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							//if is the first visible I also show the function definition
							/*if(count == 0){
							}*/

							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
				href = $(this).attr("href");

			var offset =  $(this).data('zg-offset') || 0;	
			$('html, body').animate({scrollTop: $(target).offset().top - offset } , 1000);
			window.location.hash = href;
		});
	});
	/*B2B LOGIN*/
	if($('.submit_b2b').length != 0){
		function togglePassword(id) {
			var x = document.getElementById(id);
			if (x.type === "password") {
				x.type = "text";
			} else {
				x.type = "password";
			}
		}
		$('.togglePass').click(function(){
			togglePassword('user_pwd');
			if($(this).find('.fa-eye').length!=0){
				$(this).find('.fa-eye').removeClass('fa-eye').addClass('fa-eye-slash');			
			}else{
				$(this).find('.fa-eye-slash').removeClass('fa-eye-slash').addClass('fa-eye')
			}
		});
		$('.togglePassConfirm').click(function(){
			togglePassword('user_pwd_confirm');
			if($(this).find('.fa-eye').length!=0){
				$(this).find('.fa-eye').removeClass('fa-eye').addClass('fa-eye-slash');			
			}else{
				$(this).find('.fa-eye-slash').removeClass('fa-eye-slash').addClass('fa-eye')
			}
		});
		var $regexname = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
    $('#user_pwd').on('keypress keydown keyup',function(){
				if (!$(this).val().match($regexname)) {
					// there is a mismatch, hence show the error message
						$('.emsg').removeClass('hidden');
						$('.emsg').show();
				}
			else{
						// else, do not display message
						$('.emsg').addClass('hidden');
					}
         });

		/*if( '0' != $('[data-b2b-user-id]').attr('data-b2b-user-id')){
			if(zgReadCookie('b2b-user-id') !=  null){
				zgEraseCookie('b2b-user-id');
				location.href="/";
			}
		}else{
			zgCreateCookie('b2b-user-id','0',1);
		}*/
		if($('[data-b2b-user-id]').length != 0){
			zgCreateCookie('b2b_user_id','0',1);
		}else{
			zgEraseCookie('b2b_user_id');
		}
		$('#shipping_address_country_id').on('change',function(){
			if($('#shipping_address_country_id').val() == '106'){
				$('#fiscal_code').parent('div').removeClass('hidden');
			}else{
				$('#fiscal_code').parent('div').addClass('hidden');
			}
		});
		$('.submit_b2b').click(function(){
			if($('#shipping_address_country_id').val() == '106'){
				if($('#shipping_address_p_iva').val() != "" ){				
					$('#fiscal_code').prop('required',false);
					$('#fiscal_code').removeAttr('required');
				}else{
					$('#fiscal_code').prop("required", true);
					$('#fiscal_code').attr("required", "true");
				}
				if($('#fiscal_code').val() != "" ){				
					$('#shipping_address_p_iva').prop('required',false);
					$('#shipping_address_p_iva').removeAttr('required');
				}else{
					$('#shipping_address_p_iva').prop("required", true);
					$('#shipping_address_p_iva').attr("required", "true");
				}
			}else{
				$('#shipping_address_p_iva').prop("required", true);
				$('#fiscal_code').prop('required',false);
				$('#fiscal_code').removeAttr('required');
				$('#shipping_address_p_iva').attr("required", "true");
			}
			$(this).parents('form').find('[type="submit"]').trigger('click');
		});
	}
	/*END B2B LOGIN*/


	// ===== Scroll to Top ====
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click(function() {
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	// START BUTTONS + - FOR QUANTITY
	$(document).ready(function() {	
		$(document).on('click','[data-zg-role="change-qty"]',function(){
			var qtyValue = parseInt( $( this ).parent().find( '[data-zg-role="quantity"]' ).val() );		
			if( $( this ).data('value') == '+'){
				$( this ).parent().find( '[data-zg-role="quantity"]' ).val( qtyValue + 1).trigger('change');
			}else{
				if(qtyValue > 1){
					$( this ).parent().find( '[data-zg-role="quantity"]' ).val( qtyValue - 1).trigger('change');
				}
			}
		});
	});
	// END BUTTONS + - FOR QUANTITY

	$(document).ready(function() {
		// dropdown animation
		$('.menuCnt .dropdown,.mobileSearch').on('show.bs.dropdown', function(e) {
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown(250);
		});
		$('.menuCnt .dropdown,.mobileSearch').on('hide.bs.dropdown', function(e) {
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp(100);
		});

		$(document).on('click','#sgm-mobile-input-cancel',function(){
			$('div.seg-search-wrapper.sgm-search-mobile').removeClass('open');
			$(".mobileSearch .dropdown-menu").dropdown("toggle");
		}); 

	});

	$(document).ready(function() {  
		if( $("#voucher_amount").length){
			
			$('[data-zg-role="voucher_amount_custom"]').addClass('hidden');
			
			$( document ).on( 'click','[data-zg-role="voucher_amount"]',function(e){
				//e.stopPropagation();
				e.preventDefault();
				var value = $(this).data('value');
				
				if (value == 'amount'){
					$('[data-zg-role="voucher_amount_custom"]').removeClass('hidden');
					$('#voucher_amount').val('');
				}else{
					$('#voucher_amount').val(value);
					$('[data-zg-role="voucher_amount_custom"]').addClass('hidden');
				}
				
				$('[data-zg-role="voucher_amount"]').removeClass('active');
				$(this).addClass('active');
				

			}); 
		}


		if( $('[data-zg-role="gift_card_type"]').length){
			$( document ).on( 'click','[data-zg-role="gift_card_type"]',function(e){
				e.preventDefault();
				$('[data-zg-role="gift_card_type"]').removeClass('active');
				$(this).addClass('active');
				var value = $(this).data('value');
				$('#voucher_custom_fields_gift_card_type').val(value);
				$('#voucher_custom_fields_gift_card_type').valid();
			});
		}
	}); 

	

	$(document).ready(function() {  
		if( $(".natural-color-menu").length){

			$.fn.isInViewport = function() {
				var elementTop = $(this).offset().top;
				var elementBottom = elementTop + $(this).outerHeight();
				var viewportTop = $(window).scrollTop() + ($(window).height() / 2) -100;
				var viewportBottom = viewportTop + 200;
				return elementBottom > viewportTop && elementTop < viewportBottom;
			};

			$(window).on('resize scroll', function() {
				if ($('#carousel-natural-color').isInViewport()) {
					$('.natural-color-menu a').removeClass('active');
					$('[data-zg-target="#carousel-natural-color"]').addClass('active');
				}
				if ($('#colori-naturali').isInViewport()) {
					$('.natural-color-menu a').removeClass('active');
					$('[data-zg-target="#colori-naturali"]').addClass('active');
				}
				$( ".natural-color-box-container" ).each(function( index ) {
					if ($( this ).isInViewport()) {
						$('.natural-color-menu a').removeClass('active');
						$('[data-zg-target="#' + $(this).attr('id') + '"]').addClass('active');
					}
				});
			});
		}
	}); 



	$(document).ready(function() {  
		// VAT (p.iva) must be required if country is Italy

		function isValueSelected(value) {
			console.log("selected value")
    		return $('#country_id').val() == value;
  		}

		function checkSelectedValue(){
			if (isValueSelected('106')) {
				console.log("it was selected")
			} else {
				console.log("it wasn't selected")
			}
			
		}

		checkSelectedValue()


		if($(".checkout-content").length){
			//check if Italy is selected
			$("#country_id").change(
				function() {
					checkSelectedValue()
					}
			)
		}

	})


	$(document).ready(function() {  
		if( $(".flexgoodyear-menu").length){

			$.fn.isInViewport = function() {
				var elementTop = $(this).offset().top;
				var elementBottom = elementTop + $(this).outerHeight();
				var viewportTop = $(window).scrollTop() + ($(window).height() / 2) -100;
				var viewportBottom = viewportTop + 200;
				return elementBottom > viewportTop && elementTop < viewportBottom;
			};


			$(window).on('resize scroll', function() {
				if ($('#flex-goodyear').isInViewport()) {
					$('.flexgoodyear-menu a').removeClass('active');
					$('[data-zg-target="#flex-goodyear"]').addClass('active');
				}
				else if ($('#brevetto').isInViewport()) {
					$('.flexgoodyear-menu a').removeClass('active');
					$('[data-zg-target="#brevetto"]').addClass('active');
				}
				else if ($('#made-in-italy').isInViewport()) {
					$('.flexgoodyear-menu a').removeClass('active');
					$('[data-zg-target="#made-in-italy"]').addClass('active');
				}
				else if ($('#lavorazione').isInViewport()) {
					$('.flexgoodyear-menu a').removeClass('active');
					$('[data-zg-target="#lavorazione"]').addClass('active');
				}
			});

			$(document).on('click','.flexgoodyear-menu .close',function(){
				$(".flexgoodyear-menu").toggleClass('closed');
			});


		}
	}); 
	$(document).ready(function() {  
		if( $("[data-zg-role='youtube-placeholder']").length){
			$("[data-zg-role='youtube-placeholder']").click(function(){
				$(this).addClass('hidden');
				$(this).nextAll().removeClass('hidden');
			});
		}
	}); 

	$(document).ready(function() {  
		if( $("#modalVideo").length){
			$("#modalVideo .close").click(function(){
				$('#vimeo-player').attr('src','');
			});
			$( "#modalVideo" ).on('shown.bs.modal', function(){
				console.log('show');
				$('#vimeo-player').attr('src', $('#vimeo-player').data('src'));
			});
		}
		if( $(".natural-color-menu").length){
			$(document).on('click','.natural-color-menu .close',function(){
				$(".natural-color-menu").toggleClass('closed');
			});
		}
	}); 

	$('[data-zg-role="checklogin"]').click(function(e){
		e.preventDefault();
		e.stopPropagation();
		let $form = $(this).parent('form');
		let action =$form.attr('action')
		/*let data = $form.serializeArray()
		let resultData ={}
		for(var i = 0; i < data.length; i++){

			resultData[data[i].name]= data[i].value
		}
		console.log(resultData,"RESULTDATA : ")

		$.redirect(action, resultData);*/
		let data = $form.serialize()
		location.href=action+'?'+data
	});



$('#address_form_modal').on('shown.bs.modal', function (e) {

    const fiscalCodeInput = document.getElementById('p_iva_outside');
    const feedbackElement = document.getElementById('fiscal_code_feedback');
	const submitButton = $('[data-zg-role="submit-address"]')

    fiscalCodeInput.addEventListener('input', function() {
        const fiscalCode = fiscalCodeInput.value.toUpperCase();
		console.log(fiscalCode);
        if (isValidCodiceFiscale(fiscalCode)) {
            feedbackElement.style.display = 'none';
            fiscalCodeInput.classList.remove('is-invalid');
            fiscalCodeInput.classList.add('is-valid');
			submitButton.prop("disabled", false)
        } else {
            feedbackElement.style.display = 'block';
            fiscalCodeInput.classList.remove('is-valid');
            fiscalCodeInput.classList.add('is-invalid');
			submitButton.prop("disabled", true)
        }
    });
});




$(document).ready(function() {  
	if( $(".add-billing-address").length){
		var selectCountryId = $('[data-zg-role="country-id"]')
		var submitBillingAddress = $('[data-zg-role="submit-billing"]')
		var invalidFiscalCodeAlert =  $('[data-zg-role="invalid-fiscal-code-alert"]')
		var inputFiscalCode = $("#p_iva_outside")

		function disableAndShowAlert(){
			submitBillingAddress.prop("disabled", true)
			invalidFiscalCodeAlert.removeClass("hidden")
		}

		function enableAndHideAlert(){
			submitBillingAddress.prop("disabled", false)
			invalidFiscalCodeAlert.addClass("hidden")

		}

		inputFiscalCode.on("change", function(){
			let isValidFiscalCode = isValidCodiceFiscale(inputFiscalCode.val())
			let countryValue = selectCountryId.val()
			if(countryValue == "106"){

				if(isValidFiscalCode){
					enableAndHideAlert()
				}
				else{
					disableAndShowAlert()
				}
			}
		})

		selectCountryId.on("change", function(){
			let isValidFiscalCode = isValidCodiceFiscale(inputFiscalCode.val())
			let countryValue = selectCountryId.val()
			if(countryValue == "106"){
				if(isValidFiscalCode){
					enableAndHideAlert()
				}
				else{
					disableAndShowAlert()
				}
			}
			else{
				enableAndHideAlert()
			}
		})


	}})


})( jQuery );



