(function ( $, _ ) {
		window.history.scrollRestoration = 'manual';
		if (ACTUAL_CATEGORY != 0) {

			$(document).on('click', '[data-zg-role="product-link"]', function(event) {		
				var position = $(window).scrollTop();
				$('.loading-overlay').remove();
				var category_id = ACTUAL_CATEGORY;
				zgCreateCookie('last_position', position, 1);
				zgCreateCookie('last_category_id', category_id, 1);
				can_erased=false;
			
			});

			var last_position = zgReadCookie('last_position');
			var last_category_id = zgReadCookie('last_category_id');
			var flagOn= true;
			if ( last_position && last_category_id && last_category_id == ACTUAL_CATEGORY ) {

				//$('.loading-overlay').show();
				$('#category-container').addClass('loading').css('height','auto');
				$(window).on('zg.pagination.pageRendered', function() {
					if(flagOn){
						flagOn= false; 
						//let pageMinHeight=$(window).innerHeight()+last_position;
						let pageMinHeight=parseInt(200)+parseInt(last_position); 
						$('body').css('min-height',pageMinHeight+'px');
						$('html, body').animate({
							scrollTop: last_position
						}, 1200);
					}
				});	

			}


		}else{
			zgEraseCookie('last_position');
			zgEraseCookie('last_category_id');
		}	

}( jQuery, _ ));	