/**
 * zg-adyen.js
 *
 * Adyen secure credit card page
 *
 * @author: Pietro Vignola <pvignola[at]kooomo.com>
 *
 */

/**
 * @event document#zg-notification Generic notification. Used by 2002-zg-notifier.js to display the notification
 * @type {object}
 * @property {string} eventType - Typology of event. In this plugin is 'sendFormEmail'
 * @property {string} message - The notification message. In this plugin is response by backend
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */


/* global _, JS_TRANSLATIONS, adyen, handlebarsTemplates, SGL_JS_ADYEN_KEY */

(function ( $ ) {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="zg-adyen" The plugin start if there is the selector in the dom when the page load
	 */
	var selector = '[data-zg-role="zg-adyen"]';

	/**
	 * @param {string} [adyenTemplate] Handlebars template for list of card saved
	 * @param {boolean} [adyenShowPayment] True for show the button for pay (in Credit card page), false for not show it (in account page)
	 * @param {string} [adyenActionPay] Button for pay with a saved card
	 * @param {string} [adyenActionRemove] Button for delete saved card
	 * @param {string} [adyenActionReload] Button for reload the list of cards saved
	 * @param {string} [adyenCvcInput] CVC of specific card (to fill fo pay)
	 * @param {string} [adyenRecurringForm] Form in SecureAdyen.html (page for pay an order with credit card) with data of the order
	 * @param {string} [adyenRecurringFormCvc] TO CHECK
	 * @param {string} [adyenRecurringFormCardId] TO CHECK
	 */
	var DEFAULTS = {
		adyenTemplate:    'adyen-card-cart',
		adyenShowPayment: false,

		adyenActionPay:    '[data-zg-action="pay-adyen"]',
		adyenActionRemove: '[data-zg-action="remove-adyen"]',
		adyenActionReload: '[data-zg-action="reload-adyen"]',

		adyenCvcInput: 'input[name="cvc"]',

		adyenRecurringForm:       '[data-zg-role="adyen-recurring-payment-form"]',
		adyenRecurringFormCvc:    '[data-zg-role="adyen-recurring-cvc"]',
		adyenRecurringFormCardId: '[data-zg-role="adyen-recurring-card-id"]'
	};

	var LISTCARDS = {
		diners:         'Diners Club',
		discover:       'Discover',
		amex:           'American Express',
		mc:             'MasterCard',
		visa:           'Visa',
		directEbanking: 'Sofortüberweisung',
		bcmc:           'Bancontact/Mister Cash',
		maestro:        'Maestro',
		ideal:          'Ideal',
		dotpay:         'Dotpay',
		ebanking_FI:    'Finnish E-Banking',
		giropay:        'GiroPay',
		trustly:        'Trustly'
	};


	// ADYEN CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var Adyen = function ( element, options ) {
		this.$element = $( element );
		this.options  = options;

		this.$recurringForm       = $( this.options.adyenRecurringForm );
		this.$recurringFormCvc    = $( this.options.adyenRecurringFormCvc );
		this.$recurringFormCardId = $( this.options.adyenRecurringFormCardId );

		this.__setEventHandlers();
	};


	/**
	 * Format the response from adyen so we can use it in handlebars
	 *
	 * @param {Object} data
	 * @returns {{showPayment: boolean, cards: (Array|null)}}
	 */
	Adyen.prototype.formatResponse = function ( data ) {
		var cards;

		if ( data && data.response && data.response.recurringDetailsResult_details_0_recurringDetailReference ) {
			cards = [];

			_.each( data.response, function ( value, key ) {
				var splitKey = key.split( '_' );
				var index;
				var itemKey;

				if ( splitKey[1] === 'details' ) { // make sure is a card
					index   = splitKey[2]; // card index
					itemKey = splitKey.slice( 3 ).join( '_' ); // remove recurringDetailsResult_details_[index]_ and keep the rest as the actual key

					// initialize the card object
					if ( !cards[index] ) {
						cards[index] = {};
					}

					// store the information
					if ( itemKey === 'variant' ) {
						// User friendly name  :)
						cards[index][itemKey] = LISTCARDS[value];
					} else {
						cards[index][itemKey] = value;
					}
				}
			} );
		}

		return {
			showPayment: this.options.adyenShowPayment,
			cards:       cards
		};
	};


	/**
	 *
	 * @param cardId
	 */
	Adyen.prototype.payWithCard = function ( cardId ) {
		var cvcValue;

		if ( cardId ) {
			cvcValue = this.$element.find( this.options.adyenCvcInput + '[data-id="' + cardId + '"]' ).val();

			if ( cvcValue ) {
				// update the form this the cardId and CVC and submit it
				this.$recurringFormCvc.val( cvcValue );
				this.$recurringFormCardId.val( cardId );
				this.$recurringForm.submit();

				$( 'body' ).addClass( 'loading' );
			} else {
				// the CVC is not present. Show error msg
				$( document ).trigger( 'zg-error', [{
					eventType: 'zg.adyen',
					message:   JS_TRANSLATIONS['adyen.error.requiredCVC']
				}] );
			}
		} else {
			throw new Error( 'Adyen.payWithCard - missing card id' );
		}
	};


	/**
	 * Send the data from the request to handlebars
	 *
	 * @param {Object} [data]
	 */
	Adyen.prototype.renderData = function ( data ) {
		this.$element
			.html( handlebarsTemplates.render( this.options.adyenTemplate, data ) )
			.removeClass( 'loading' );
	};


	/**
	 * Remove a card from the system
	 *
	 * @param cardId
	 */

	/**
	 * @method removeCard
	 * @fires document#zg-error Ajax for remove a card in the list response error
	 */
	/**
	 * @method removeCard
	 * @fires document#zg-error Ajax for remove a card in the list make error
	 */
	/**
	 * @method removeCard
	 * @fires document#zg-notification Ajax for remove a card in the list response success
	 */
	Adyen.prototype.removeCard = function ( cardId ) {
		if ( cardId ) {
			$.ajax( {
				url: window.makeUrl( {
					module:  'eshop',
					manager: 'eshop',
					action:  'disableAdyenCreditCard'
				} ),
				type: 'post',
				data: {
					recurring_ref: cardId
				},
				dataType: 'json',
				success: ( function ( response ) {
					if ( response && response.success === 'true' ) {
						$( document ).trigger( 'zg-notification', [{
							eventType: 'zg.adyen',
							message:   JS_TRANSLATIONS['success.remove.getAdyenCreditCardDetails']
						}] );

						this.requestData();
					} else {
						$( document ).trigger( 'zg-error', [{
							eventType: 'zg.adyen',
							message:   JS_TRANSLATIONS['error.remove.getAdyenCreditCardDetails']
						}] );
					}
				}).bind( this ),

				error: ( function () {
					$( document ).trigger( 'zg-error', [{
						eventType: 'zg.adyen',
						message:   JS_TRANSLATIONS['error.remove.getAdyenCreditCardDetails']
					}] );
				}).bind( this )
			} );
		} else {
			throw new Error( 'Adyen.removeCard - missing card id' );
		}
	};


	/**
	 * Request the stored cards from Adyen
	 *
	 */
	/**
	 * @method requestData
	 * @fires document#zg-error Ajax for get cards details make an error
	 */
	Adyen.prototype.requestData = function () {
		this.$element.addClass( 'loading' );

		$.ajax( {
			type:     'post',
			url:      window.makeUrl( {module: 'eshop', manager: 'eshop', action: 'getAdyenCreditCardDetails'} ),
			dataType: 'json',
			success:  (function ( response ) {
				// send the response to formatResponse and use its result for renderData
				this.renderData( this.formatResponse( response ) );
			}).bind( this ),

			error: (function () {
				// render the 'is empty' msg
				this.renderData();

				$( document ).trigger( 'zg-error', [{
					message: JS_TRANSLATIONS['error.getAdyenCreditCardDetails']
				}] );
			}).bind( this )
		} );
	};

	/**
	 * Event Handlers
	 *
	 * @private
	 */
	Adyen.prototype.__setEventHandlers = function () {
		this.$element.on( 'click.zg.adyen', this.options.adyenActionPay, (function ( e ) {
			e.preventDefault();

			this.payWithCard( $( e.currentTarget ).data( 'id' ) );
		}).bind( this ) );

		this.$element.on( 'click.zg.adyen', this.options.adyenActionRemove, (function ( e ) {
			e.preventDefault();

			this.removeCard( $( e.currentTarget ).data( 'id' ) );
		}).bind( this ) );

		this.$element.on( 'click.zg.adyen', this.options.adyenActionReload, (function ( e ) {
			e.preventDefault();

			this.requestData();
		}).bind( this ) );
	};


	// ADYEN PLUGIN DEFINITION
	// =======================

	function Plugin () {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.adyen' );
			var options = $.extend( {}, DEFAULTS, root.ZG_CONFIG || {}, $this.data() );

			if ( !data ) {
				$this.data( 'zg.adyen', (data = new Adyen( this, options )) );
			}

			data.requestData();
		} );
	}

	$.fn.zgAdyen             = Plugin;
	$.fn.zgAdyen.Constructor = Adyen;

	//
	// ================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );
}).call( this, jQuery );


(function ( $ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	// start adyen encrypt
	$( function () {
		var form = document.getElementById( 'adyen-encrypted-form' );
		var options;

		if ( form && (typeof SGL_JS_ADYEN_KEY !== 'undefined') ) {
            if (
                root.adyen &&
                adyen.encrypt &&
                adyen.encrypt.createEncryptedForm ) {
				// Adyen encryption options
				// https://github.com/Adyen/CSE-JS/blob/master/Options.md
				options = {
					// callback for the payment form submit
					onsubmit: function submitCallback () {
						form.setAttribute( 'disabled', true );
						$( 'body' ).addClass( 'loading' );
					}
				};
                adyen.encrypt.createEncryptedForm( form, SGL_JS_ADYEN_KEY, options);
			} else {
				throw new Error( 'Adyen encrypt library is missing' );
			}
		}
	} );
}).call( this, jQuery );